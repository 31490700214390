'use strict';

import FormData from 'form-data';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const {createMachine, assign} = require('xstate');

const completeDocumentsUploadMachine = createMachine({
    id: 'completeDocumentsUploadForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        user: {},
        userCurrentData: {},
        documentIdToDelete: null,
        newFilesToUpload: [],
        error: {
            data: {}
        }
    },
    states: {
        init: {
            id: 'init',
            entry: assign({
                userCurrentData: ({userCurrentData}) => ({
                    ...userCurrentData,
                    documents: userCurrentData.documents || []
                }),
                newFilesToUpload: ({newFilesToUpload}) => newFilesToUpload
            }),
            always: {
                target: '#edit'
            }
        },
        edit: {
            id: 'edit',
            on: {
                SUBMIT: {
                    target: 'save',
                    actions: assign({
                        userCurrentData: ({userCurrentData}, {filesToUpload}) => ({
                            ...userCurrentData,
                            filesToUpload
                        })
                    })
                },
                DELETEFILE: {
                    target: 'deleteFile',
                    actions: assign({
                        documentIdToDelete: (_, {fileId}) => fileId
                    })
                },
                ADDFILES: {
                    target: 'addFiles',
                    actions: assign({
                        newFilesToUpload: (_, {files}) => files
                    })
                },
                BACK: {
                    target: 'done'
                }
            }
        },
        addFiles: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.documentsUploadDocumentApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'POST',
                    headers: {},
                    data: ({newFilesToUpload}) => {

                        const formData = new FormData();
                        if (newFilesToUpload.length > 0) {
                            for (const document of newFilesToUpload) {
                                formData.append('documents', document);
                            }
                        }

                        return formData;
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => data.response,
                        newFilesToUpload: [],
                        error: {
                            data: {}
                        }
                    }),
                    target: '#init'
                },
                onError: {
                    actions: assign({
                        error: (_, {data}) => ({data})
                    }),
                    target: '#init'
                }
            }
        },
        deleteFile: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData, documentIdToDelete}) => {
                        return api.documentsUploadDocumentSingleApiUrlTemplate(userCurrentData.id, documentIdToDelete)
                    },
                    method: 'DELETE'
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => data.response,
                        documentIdToDelete: null,
                        error: {
                            data: {}
                        }
                    }),
                    target: '#init'
                },
                onError: {
                    target: '#init',
                    actions: assign({
                        documentIdToDelete: null
                    })
                }
            }
        },
        save: {
            id: 'save',
            initial: 'saveData',
            states: {
                saveData: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({userCurrentData}) => {
                                return api.documentsUploadSingleApiUrlTemplate(userCurrentData.id)
                            },
                            method: 'POST'
                        },
                        onDone: {
                            actions: assign({
                                userCurrentData: (_, {data}) => data,
                                error: {
                                    data: {}
                                }
                            }),
                            target: '#done'
                        },
                        onError: {
                            target: '#init'
                        }
                    }
                }
            }
        },
        done: {
            id: 'done',
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default completeDocumentsUploadMachine;
