'use strict';

import React, {useEffect} from 'react';
import PropTypes          from 'prop-types';
import {BsCloudUpload} from 'react-icons/bs';
import {useTranslation}   from 'react-i18next';

export const UploadField = ({
    formik,
    documents,
    addFilesCallback,
    handleDrag,
    handleDrop,
    dragActive,
    errorCodes
}) => {

    const {t} = useTranslation();
    const {setFieldValue} = formik;

    useEffect(() => {
        let hasDocuments = false;

        if (Array.isArray(documents) && documents.length > 0) {
            hasDocuments = true;
        }

        setFieldValue(
            'hasFilesUploaded',
            hasDocuments,
            false
        );
    }, [documents, setFieldValue]);

    return (
        <div id='drop-area'
            className={dragActive ? 'drag-active upload-container' :
                'upload-container' }>
            <BsCloudUpload className={'icon-upload'}/>
            <label htmlFor={'filesToUpload'}>
                Dokumente hochladen
                <span className={'upload-formats'}>
                    (Unterstützte Formate: PDF, JPEG, JPG, BMP, PNG)
                </span>
            </label>
            { dragActive &&
                <div id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}>
                </div> }
            {
                formik.errors && formik.errors.filesToUpload ?
                    <div className={'errors'}>
                        {formik.errors.filesToUpload}
                    </div>
                    : null
            }
            {
                formik.errors && formik.errors.hasFilesUploaded ?
                    <div className={'errors'}>
                        {formik.errors.hasFilesUploaded}
                    </div>
                    : null
            }
            {
                errorCodes &&
                    <div className={'errors'}>
                        {t((Array.isArray(errorCodes) ? errorCodes[0] : errorCodes), {fileSizeInMb: 10})}
                    </div>
            }
            <input
                type={'file'}
                name={'filesToUpload'}
                id={'filesToUpload'}
                className={
                    formik.errors && formik.errors.filesToUpload ?
                        'error' :
                        ''
                }
                onChange={(event) => {
                    const {currentTarget, target} = event;
                    return addFilesCallback(currentTarget.files, target.files, formik)
                        .then(() => {

                            // clearing target.value gives us the opportunity to upload the sam file multiple times.
                            // this could occur if user uploads than removes than try to upload the same file.
                            target.value = null;
                        });
                }}
                multiple={true}
            />
        </div>
    );
};

UploadField.propTypes = {
    formik: PropTypes.shape(),
    documents: PropTypes.array,
    addFilesCallback: PropTypes.func,
    handleDrag: PropTypes.func,
    handleDrop: PropTypes.func,
    dragActive: PropTypes.bool,
    errorCodes: PropTypes.array
};
