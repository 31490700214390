'use strict';

import React             from 'react';
import {useParams, Link} from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const VerifyEmailStatus = () => {

    const {status} = useParams();

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Bestätigung E-Mail Adresse</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x verify'}>
                    <div className={'grid-y cell auto'}>
                        {status === 'success' &&
                            <p>
                                Vielen Dank für die Validierung Ihrer E-Mail Adresse. Sie können sich jetzt anmelden.
                            </p>
                        }
                        {status === 'error' &&
                            <>
                                <p>
                                    Dieser Link ist nicht mehr gültig.
                                </p>
                                <p>
                                    Melden Sie sich bitte noch mal an und fordern
                                    Sie erneut die Bestätigung-E-Mail an.
                                </p>
                            </>
                        }

                        <Link
                            to={'/'}
                            className={'button primary margin-top-2'}
                        >
                            Zur Anmeldung
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
