'use strict';

import React      from 'react';
import PropTypes  from 'prop-types';
import {useActor} from '@xstate/react';

export const ResumeForm = ({service}) => {
    const [state, send] = useActor(service);
    const {userName} = state.context;

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Willkommen zurück, {userName}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x verify'}>
                    <div className={'grid-y cell auto'}>
                        <p>
                            Ihre Anmeldung ist noch unvollständig.
                        </p>
                        <p>
                            Auf der nächsten Seite finden Sie die von Ihnen erfassten Angaben,
                            bitte kontrollieren Sie diese und ergänzen Sie die fehlenden Angaben.
                        </p>

                        <div>
                            <button
                                className={'button primary margin-top-2'}
                                type={'button'}
                                onClick={() => send('SUBMIT')}
                            >
                                Fortfahren
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

ResumeForm.propTypes = {
    service: PropTypes.any
}
