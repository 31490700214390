'use strict';

import {context}                 from './lib/fetchMachineContext';
import {api}                     from './lib/conf';

const {createMachine, assign} = require('xstate');

const currenSchoolMachine = createMachine({
    id: 'currenSchoolForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        userCurrentData: {},
        error: {
            data: {}
        },
        userName: ''
    },
    states: {
        init: {
            entry: assign({
                userCurrentData: ({userCurrentData}) => ({
                    ...userCurrentData,
                    schoolType: userCurrentData.schoolType || '',
                    schoolName: userCurrentData.schoolName || '',
                    schoolMunicipality: userCurrentData.schoolMunicipality || '',
                    classYears: userCurrentData.classYears || '',
                    schoolOtherName: userCurrentData.schoolOtherName || '',
                    schoolOtherMunicipality: userCurrentData.schoolOtherMunicipality || '',
                    classOtherYears: userCurrentData.classOtherYears || ''
                })
            }),
            always: {
                target: 'edit'
            }
        },
        edit: {
            on: {
                SUBMIT: {
                    target: 'saveData',
                    actions: assign({
                        userCurrentData: ({userCurrentData}, event) => ({
                            ...userCurrentData,
                            schoolType: event.schoolType || '',
                            schoolName: event.schoolName || '',
                            schoolMunicipality: event.schoolMunicipality || '',
                            classYears: event.classYears || '',
                            schoolOtherName: event.schoolOtherName || '',
                            schoolOtherMunicipality: event.schoolOtherMunicipality || '',
                            classOtherYears: event.classOtherYears || ''
                        })
                    })
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.currentSchoolSingleApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'PUT',
                    data: ({userCurrentData}) => {

                        return {
                            ...userCurrentData,
                            editing: 0,
                            complete: 1
                        };
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => {
                            return data;
                        }
                    }),
                    target: 'done'
                },
                onError: {
                    actions: assign({
                        error: (_, {data}) => ({data})
                    }),
                    target: 'edit'
                }
            }
        },
        done: {
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default currenSchoolMachine;
