'use strict';

import React            from 'react';
import PropTypes        from 'prop-types';
import {useActor}       from '@xstate/react';
import {BiPencil}       from 'react-icons/bi';
import {useTranslation} from 'react-i18next';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ChangePasswordButton = ({service, changeOpenState}) => {

    const [, send] = useActor(service);
    const {t} = useTranslation();

    return (
        <div className="change-password">
            <button
                type={'button'}
                className={'clear button'}
                onClick={() => {
                    changeOpenState(false);
                    send('CHANGEPASSWORD');
                }}
            >
                <BiPencil className="nav-icon"/>
                <span>{t('changePassword')}</span>
            </button>
        </div>
    )
};

ChangePasswordButton.propTypes = {
    service: PropTypes.shape(),
    changeOpenState: PropTypes.func
};
