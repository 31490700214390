'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import {useSelector}          from '@xstate/react';
import {ChangePasswordButton} from '../changePasswordButton';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ChangePasswordButtonWrapper = ({service, changeOpenState}) => {
    const kttgApplicationMachine = service.children.get('kttgApplication');
    const applicationFormService = kttgApplicationMachine.children.get('applicationForm');

    const inForm = useSelector(kttgApplicationMachine, state => state.matches('form'));
    const inVerifyEmail = useSelector(kttgApplicationMachine, state => state.matches('verifyEmail'));

    return (
        <>
            {inForm &&
                <li>
                    <ChangePasswordButton
                        service={applicationFormService}
                        changeOpenState={changeOpenState}
                    />
                </li>
            }
            {!inForm && !inVerifyEmail &&
                <li>
                    <ChangePasswordButton
                        service={kttgApplicationMachine}
                        changeOpenState={changeOpenState}
                    />
                </li>
            }
        </>
    )
};

ChangePasswordButtonWrapper.propTypes = {
    service: PropTypes.shape(),
    changeOpenState: PropTypes.func
};
