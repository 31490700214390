'use strict';

import React                from 'react';
import PropTypes            from 'prop-types';
import {useSelector}        from '@xstate/react';
import Loader               from '../loader';
import {DocumentUploadForm} from '../documentUploadForm';

export const DocumentUploadContainer = ({applicationFormService}) => {
    const documentUploadMachine = applicationFormService.children.get('documentsUploadForm');

    const isLoading = useSelector(
        documentUploadMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <DocumentUploadForm
                    service={documentUploadMachine}
                />
            }
        </>
    )
};

DocumentUploadContainer.propTypes = {
    applicationFormService: PropTypes.any
}
