'use strict';

import {createMachine, assign, sendParent} from 'xstate';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const initialContext = {
    user: null,
    registrationData: {},
    errorMessage: '',
    error: {
        data: {}
    },
    texts: [],
    showResetRegistrationButton: false
}

const kttgMachine = createMachine({
    id: 'kttgApplication',
    predictableActionArguments: true,
    initial: 'init',
    context: initialContext,
    states: {
        init: {
            id: 'init',
            entry: assign({
                errorMessage: ''
            }),
            invoke: {
                src: ({registrationData, user}) => callback => {
                    if (!user.emailValidated) {
                        return callback('NOTVERIFIED');
                    }
                    if (registrationData.complete) {
                        return callback('COMPLETE')
                    }

                    return callback('FORM')
                }
            },
            on: {
                NOTVERIFIED: {
                    target: 'verifyEmail'
                },
                COMPLETE: {
                    target: 'registrationComplete'
                },
                FORM: {
                    target: 'form'
                },
                DOCUMENTSUPLOAD: {
                    target: 'documentsUpload'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        form: {
            invoke: {
                id: 'applicationForm',
                src: 'applicationForm',
                data: {
                    registrationData: ({registrationData}) => registrationData,
                    user: ({user}) => user,
                    error: {
                        data: {}
                    }
                },
                onDone: {
                    target: 'registrationComplete',
                    actions: assign({
                        user: (_, {data}) => data,
                        registrationData: (_, {data}) => data.schoolApplication
                    })
                },
                onError: {
                    actions: assign({
                        errorMessage: (_, {data}) => data
                    })
                }
            }
        },
        registrationComplete: {
            id: 'registrationComplete',
            initial: 'getTexts',
            states: {
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('registrationComplete')
                        },
                        onDone: {
                            target: 'checkUserAllowedToResetRegistration',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {
                            target: 'checkUserAllowedToResetRegistration'
                        }
                    }
                },
                checkUserAllowedToResetRegistration: {
                    invoke: {
                        src: ({registrationData = {}}) => {
                            const {data_reset: dataReset = 1} = registrationData;

                            if (dataReset) {
                                return Promise.reject();
                            }

                            return Promise.resolve();
                        },
                        onDone: {
                            target: 'checkResetButtonEnabled'
                        },
                        onError: {
                            target: 'view'
                        }
                    }
                },
                checkResetButtonEnabled: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.isResetButtonEnabledApiUrlTemplate()
                        },
                        onDone: {
                            target: 'view',
                            actions: assign({
                                showResetRegistrationButton: (_, {data}) => {
                                    const response = data.response ? data.response : data;
                                    return parseInt(response.enabled) === 1;
                                }
                            })
                        },
                        onError: {
                            target: 'view'
                        }
                    }
                },
                view: {
                    id: 'view'
                },
                resetUserData: {
                    id: 'resetUserData',
                    initial: 'confirmation',
                    states: {
                        confirmation: {
                            on: {
                                CONFIRM: {
                                    target: 'processing'
                                },
                                CANCEL: {
                                    target: '#view'
                                }
                            }
                        },
                        processing: {
                            invoke: {
                                src: 'apiCall',
                                data: {
                                    url: api.resetUserDataApiUrlTemplate,
                                    method: 'PUT'
                                },
                                onDone: {
                                    target: 'success',
                                    actions: assign({
                                        user: (_, {data}) => data.response ? data.response : data,
                                        registrationData: (_, {data}) => {
                                            const user = data.response ? data.response : data;
                                            return user.schoolApplication || {};
                                        }
                                    })
                                },
                                onError: {
                                    target: 'error'
                                }
                            }
                        },
                        success: {
                            on: {
                                CONTINUE: {
                                    target: '#init'
                                }
                            }
                        },
                        error: {
                            on: {
                                CANCEL: {
                                    target: '#view'
                                }
                            }
                        }
                    }
                }
            },
            on: {
                DOCUMENTSUPLOAD: {
                    target: 'documentsUpload'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                },
                RESETUSERDATA: {
                    target: '#resetUserData'
                }
            }
        },
        documentsUpload: {
            id: 'documentsUpload',
            initial: 'getTexts',
            states: {
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('registrationCompleteDocumentsUpload')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'completeDocumentsUploadForm',
                        src: 'completeDocumentsUploadForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.documentsUpload || {},
                            user: ({user}) => user || {},
                            texts: ({texts}) => texts || {}
                        },
                        onDone: {
                            target: '#registrationComplete',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => {
                                    const documentsUpload = data.response ? data.response : data;
                                    return ({
                                        ...registrationData,
                                        documentsUpload
                                    })
                                },
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;
                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                }
            },
            on: {
                BACK: {
                    target: 'registrationComplete'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        changePassword: {
            initial: 'edit',
            states: {
                edit: {
                    on: {
                        SUBMIT: {
                            target: 'changePassword'
                        },
                        BACK: {
                            target: '#init'
                        }
                    }
                },
                changePassword: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.changePasswordByUserApiUrlTemplate,
                            method: 'POST',
                            data: (_, {oldPassword, password, passwordRepeat}) => ({
                                password,
                                passwordRepeat,
                                oldPassword
                            })
                        },
                        onDone: {
                            target: 'changePasswordSuccess'
                        },
                        onError: {
                            target: 'changePasswordError'
                        }
                    }
                },
                changePasswordSuccess: {
                    on: {
                        BACK: {
                            target: '#init'
                        }
                    }
                },
                changePasswordError: {
                    on: {
                        BACK: {
                            target: '#init'
                        }
                    }
                }
            }
        },
        verifyEmail: {
            initial: 'initial',
            states: {
                initial: {},
                requestNewEmail: {
                    id: 'requestNewEmail',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.requestVerificationEmailApiUrlTemplate,
                            method: 'POST'
                        },
                        onDone: {
                            target: 'requestNewEmailSuccess'
                        },
                        onError: {
                            target: 'requestNewEmailError'
                        }
                    }
                },
                requestNewEmailSuccess: {
                    on: {
                        LOGOUT: {
                            actions: sendParent('LOGOUT')
                        }
                    }
                },
                requestNewEmailError: {
                    on: {
                        LOGOUT: {
                            actions: sendParent('LOGOUT')
                        },
                        REQUEST_VALIDATION_EMAIL: {
                            target: '#requestNewEmail'
                        }
                    }
                }
            },
            on: {
                LOGOUT: {
                    actions: sendParent('LOGOUT')
                },
                REQUEST_VALIDATION_EMAIL: {
                    target: '#requestNewEmail'
                }
            }
        }
    }
});

export default kttgMachine;
