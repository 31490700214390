'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';
import Loader        from '../loader';
import {LegalRepresentativeForm} from '../legalRepresentativeForm';

export const LegalRepresentativeContainer = ({applicationFormService}) => {
    const legalRepresentativeMachine = applicationFormService.children.get('legalRepresentativeForm');

    const isLoading = useSelector(
        legalRepresentativeMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <LegalRepresentativeForm
                    service={legalRepresentativeMachine}
                />
            }
        </>
    )
};

LegalRepresentativeContainer.propTypes = {
    applicationFormService: PropTypes.any
}
