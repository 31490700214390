'use strict';

import React                 from 'react';
import PropTypes             from 'prop-types';
import {useActor}            from '@xstate/react';
import {useFormik}           from 'formik';
import {
    string,
    object
}                            from 'yup';

import { FormGuide }         from '../formGuide';
import {InputAdvancedSelect} from '../input-advanced-select';
import {useTranslation}      from 'react-i18next';

export const CurrentSchoolForm = ({service}) => {
    const [state, send] = useActor(service);
    const {t} = useTranslation();

    const {error: errorObject = {}} = state.context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const {
        schoolMunicipalityMapping,
        userCurrentData,
        texts: formText,
        userName
    } = state.context;

    const payload = {
        schoolType: userCurrentData.schoolType || '',
        schoolName: userCurrentData.schoolName || '',
        schoolMunicipality: userCurrentData.schoolMunicipality || '',
        classYears: userCurrentData.classYears || '',
        schoolOtherName: userCurrentData.schoolOtherName || '',
        schoolOtherMunicipality: userCurrentData.schoolOtherMunicipality || '',
        classOtherYears: userCurrentData.classOtherYears || ''
    };

    const validationSchema = object({
        schoolType: string()
            .oneOf([
                t('publicSchool'),
                t('otherSchool'),
                null
            ], t('invalideSchoolType')),
        schoolName: string()
            .oneOf([
                ...Object.keys(schoolMunicipalityMapping),
                null
            ], t('invalidSchoolName'))
            .when('schoolType', {
                is: t('publicSchool'),
                then: string().required(t('schoolNameRequiredMessage'))
            }),
        schoolOtherName: string(),
        schoolMunicipality: string()
            .oneOf([
                ...Object.values(schoolMunicipalityMapping),
                null
            ], t('invalideSchoolMunicipality'))
            .when('schoolType', {
                is: t('publicSchool'),
                then: string().required(t('schoolMunicipalityRequiredMessage'))
            }),
        schoolOtherMunicipality: string(),
        classYears: string()
            .when('schoolType', {
                is: t('publicSchool'),
                then: string().required(t('currentClassRequiredMessage'))
            }),
        classOtherYears: string()
    });

    const convertSchoolsToObject = schoolMunicipalityMapping => {
        let index = 0;
        return Object.keys(schoolMunicipalityMapping).reduce((prev, curr) => {
            prev[index] = {
                name: curr,
                id: curr
            };

            index++;
            return prev;
        }, {});
    };

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return send(
                {
                    type: 'SUBMIT',
                    ...values
                }
            );
        }
    });

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>{t('currentSchool')}: {userName}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <div>

                                    {errorCodes &&
                                        <div className={'errors'}>
                                            {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                        </div>
                                    }

                                    <FormGuide html={formText[0] ? formText[0] : ''}>
                                        <label htmlFor={'schoolType'}>{t('currentSchool')} *</label>
                                        {formik.errors.schoolType && formik.touched.schoolType ?
                                            <div className={'errors'}>{formik.errors.schoolType}</div>
                                            : null}
                                        <span className={'select-wrapper'}>
                                            <select
                                                name={'schoolType'}
                                                value={formik.values.schoolType}
                                                className={
                                                    formik.errors.schoolType &&
                                                    formik.touched.schoolType ?
                                                        'error' :
                                                        ''
                                                }
                                                onChange={formik.handleChange}
                                            >
                                                <option value={''} disabled={true}>{t('optionsDefaultValue')}</option>
                                                <option value={t('publicSchool').toString()}>
                                                    {t('publicSchool')}
                                                </option>
                                                <option value={t('otherSchool').toString()}>{t('otherSchool')}</option>
                                            </select>
                                        </span>
                                    </FormGuide>
                                    {formik.values.schoolType === t('publicSchool') &&
                                        <>
                                            <FormGuide html={formText[1] ? formText[1] : ''}>
                                                <label htmlFor={'schoolName'}>{t('schoolName')} *</label>
                                                {formik.errors.schoolName && formik.touched.schoolName ?
                                                    <div className={'errors'}>{formik.errors.schoolName}</div>
                                                    : null}
                                                <InputAdvancedSelect
                                                    options={[]}
                                                    suggestions={
                                                        convertSchoolsToObject(schoolMunicipalityMapping) ||
                                                        {}}
                                                    pleaseChoose={t('optionsDefaultValue')}
                                                    errorClass={
                                                        formik.errors.schoolName &&
                                                        formik.touched.schoolName ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={({item}) => {
                                                        formik.setFieldValue('schoolName', item.id);
                                                        formik.setFieldValue('schoolMunicipality',
                                                            schoolMunicipalityMapping[item.id]);
                                                    }}
                                                    value={formik.values.schoolName}
                                                    initialValue={''}
                                                    name={'schoolName'}
                                                    showSuggestionsOnFocus={true}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[2] ? formText[2] : ''}>
                                                <label aria-disabled htmlFor={'schoolMunicipality'}>
                                                    {t('schoolMunicipality')} *
                                                </label>
                                                <input
                                                    type={'text'}
                                                    name={'schoolMunicipality'}
                                                    value={formik.values.schoolMunicipality}
                                                    disabled={true}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[3] ? formText[3] : ''}>
                                                <label htmlFor={'classYears'}>{t('classYears')} *</label>
                                                {formik.errors.classYears && formik.touched.classYears ?
                                                    <div className={'errors'}>{formik.errors.classYears}</div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'classYears'}
                                                    value={formik.values.classYears}
                                                    className={
                                                        formik.errors.classYears &&
                                                        formik.touched.classYears ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                        </>
                                    }
                                    {formik.values.schoolType === t('otherSchool') &&
                                        <>
                                            <FormGuide html={formText[4] ? formText[4] : ''}>
                                                <label htmlFor={'schoolOtherName'}>{t('schoolName')}</label>
                                                {formik.errors.schoolOtherName && formik.touched.schoolOtherName ?
                                                    <div className={'errors'}>{formik.errors.schoolOtherName}</div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'schoolOtherName'}
                                                    className={
                                                        formik.errors.schoolOtherName &&
                                                        formik.touched.schoolOtherName ?
                                                            'error' :
                                                            ''
                                                    }
                                                    value={formik.values.schoolOtherName}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[5] ? formText[5] : ''}>
                                                <label htmlFor={'schoolOtherMunicipality'}>
                                                    {t('schoolMunicipality')}
                                                </label>
                                                {formik.errors.schoolOtherMunicipality
                                                && formik.touched.schoolOtherMunicipality ?
                                                    <div className={'errors'}>
                                                        {formik.errors.schoolOtherMunicipality}
                                                    </div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'schoolOtherMunicipality'}
                                                    value={formik.values.schoolOtherMunicipality}
                                                    className={
                                                        formik.errors.schoolOtherMunicipality &&
                                                        formik.touched.schoolOtherMunicipality ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[6] ? formText[6] : ''}>
                                                <label htmlFor={'classOtherYears'}>{t('classYears')}</label>
                                                {formik.errors.classOtherYears && formik.touched.classOtherYears ?
                                                    <div className={'errors'}>{formik.errors.classOtherYears}</div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'classOtherYears'}
                                                    value={formik.values.classOtherYears}
                                                    className={
                                                        formik.errors.classOtherYears &&
                                                        formik.touched.classOtherYears ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                        </>
                                    }
                                </div>
                                <button
                                    type={'submit'}
                                    className={'submit button primary margin-top-2'}
                                >
                                    {t('continue')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

CurrentSchoolForm.propTypes = {
    service: PropTypes.any
};
