'use strict';

import React                          from 'react';
import PropTypes                      from 'prop-types';
import {useActor}                     from '@xstate/react';
import {useFormik}                    from 'formik';
import {
    string,
    object, boolean
} from 'yup';

import { FormGuide }                  from '../formGuide';


export const LegalRepresentativeForm = ({service}) => {
    const [state, send] = useActor(service);
    const {context} = state;
    const {
        userCurrentData,
        salutations = [
            {
                id: 1,
                name: 'Herr'
            },
            {
                id: 2,
                name: 'Frau'
            }
        ],
        userName,
        texts: formText
    } = context;

    const payload = {
        salutation: userCurrentData.salutation || '',
        firstName: userCurrentData.firstName || '',
        lastName: userCurrentData.lastName || '',
        street: userCurrentData.street || '',
        streetNumber: userCurrentData.streetNumber || '',
        zip: userCurrentData.zip || '',
        city: userCurrentData.city || '',
        mobile: userCurrentData.mobile || '',
        phone: userCurrentData.phone || '',
        email: userCurrentData.email || '',
        hasAlternativeLegalRepresentative: userCurrentData.hasAlternativeLegalRepresentative,
        alternativeLegalRepresentativeSalutation: userCurrentData.alternativeLegalRepresentativeSalutation || '',
        alternativeLegalRepresentativeFirstName: userCurrentData.alternativeLegalRepresentativeFirstName || '',
        alternativeLegalRepresentativeLastName: userCurrentData.alternativeLegalRepresentativeLastName || '',
        alternativeLegalRepresentativeStreet: userCurrentData.alternativeLegalRepresentativeStreet || '',
        alternativeLegalRepresentativeStreetNumber: userCurrentData.alternativeLegalRepresentativeStreetNumber || '',
        alternativeLegalRepresentativeZip: userCurrentData.alternativeLegalRepresentativeZip || '',
        alternativeLegalRepresentativeCity: userCurrentData.alternativeLegalRepresentativeCity || '',
        alternativeLegalRepresentativeMobile: userCurrentData.alternativeLegalRepresentativeMobile || '',
        alternativeLegalRepresentativeEmail: userCurrentData.alternativeLegalRepresentativeEmail || '',
    };

    const validationSchema = object({
        salutation: string()
            .oneOf([
                ...salutations.map(({id}) => id),
                null
            ], 'Invalide Anrede')
            .required('Anrede ist ein Pflichtfeld'),
        firstName: string().required('Vorname ist ein Pflichtfeld'),
        lastName: string().required('Nachname ist ein Pflichtfeld'),
        street: string().required('Strasse ist ein Pflichtfeld'),
        streetNumber: string().required('Strassennummer ist ein Pflichtfeld'),
        zip: string().required('PLZ ist ein Pflichtfeld'),
        city: string().required('Ort ist ein Pflichtfeld'),
        mobile: string().required('Telephon (Mobil) ist ein Pflichtfeld'),
        phone: string(),
        email: string().email('Ungültige E-Mail').required('E-Mail ist ein Pflichtfeld'),
        hasAlternativeLegalRepresentative: boolean(),
        alternativeLegalRepresentativeSalutation: string()
            .when('hasAlternativeLegalRepresentative', {
                is: true,
                then: string()
                    .oneOf([
                        ...salutations.map(({id}) => id),
                        null
                    ], 'Invalide Anrede')
                    .required('Anrede ist ein Pflichtfeld')
            }),
        alternativeLegalRepresentativeFirstName: string()
            .when('hasAlternativeLegalRepresentative', {
                is: true,
                then: string().required('Vorname ist ein Pflichtfeld')
            }),
        alternativeLegalRepresentativeLastName: string()
            .when('hasAlternativeLegalRepresentative', {
                is: true,
                then: string().required('Nachname ist ein Pflichtfeld')
            }),
        alternativeLegalRepresentativeStreet: string(),
        alternativeLegalRepresentativeStreetNumber: string(),
        alternativeLegalRepresentativeZip: string(),
        alternativeLegalRepresentativeCity: string(),
        alternativeLegalRepresentativeMobile: string(),
        alternativeLegalRepresentativeEmail: string().email('Ungültige Email')
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return send(
                {
                    type: 'SUBMIT',
                    ...values
                }
            );
        }
    });

    const getSalutationOptions = () => {
        return salutations.map(({id, name}) => {
            return <option key={id} value={id}>{name}</option>;
        });
    };

    const formTextIndexMapping = {
        salutation: formText[0] || '',
        firstName: formText[1] || '',
        lastName: formText[2] || '',
        street: formText[3] || '',
        streetNumber: formText[4] || '',
        zip: formText[5] || '',
        city: formText[6] || '',
        mobile: formText[7] || '',
        phone: formText[8] || '',
        email: formText[9] || '',
        alternativeLegalRepresentativeSalutation: formText[10] || '',
        alternativeLegalRepresentativeFirstName: formText[11] || '',
        alternativeLegalRepresentativeLastName: formText[12] || '',
        alternativeLegalRepresentativeStreet: formText[13] || '',
        alternativeLegalRepresentativeStreetNumber: formText[14] || '',
        alternativeLegalRepresentativeZip: formText[15] || '',
        alternativeLegalRepresentativeCity: formText[16] || '',
        alternativeLegalRepresentativeMobile: formText[17] || '',
        alternativeLegalRepresentativeEmail: formText[18] || '',
    }

    const getPersonInputs = usePrefix => {
        const prefix = 'alternativeLegalRepresentative';
        const applyPrefixedName = (usePrefix, name) => {
            if (!usePrefix) {
                return name;
            }

            return `${prefix}${name[0].toUpperCase()}${name.slice(1)}`
        }

        return (
            <>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'salutation')]}>
                    <label htmlFor={'salutation'}>Anrede *</label>
                    {!usePrefix && formik.errors.salutation && formik.touched.salutation ?
                        <div className={'errors'}>{formik.errors.salutation}</div>
                        : null}
                    {usePrefix &&
                    formik.errors.alternativeLegalRepresentativeSalutation &&
                    formik.touched.alternativeLegalRepresentativeSalutation ?
                        <div className={'errors'}>{formik.errors.alternativeLegalRepresentativeSalutation}</div>
                        : null}
                    <span className={'select-wrapper'}>
                        <select
                            name={applyPrefixedName(usePrefix, 'salutation')}
                            value={formik.values[applyPrefixedName(usePrefix, 'salutation')]}
                            className={
                                !usePrefix && formik.errors.salutation && formik.touched.salutation ?
                                    'error' : usePrefix &&
                                    formik.errors.alternativeLegalRepresentativeSalutation &&
                                    formik.touched.alternativeLegalRepresentativeSalutation ?
                                        'error' : ''
                            }
                            onChange={formik.handleChange}
                        >
                            <option value={''} disabled={true}>- Bitte wählen -</option>
                            {getSalutationOptions()}
                        </select>
                    </span>
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'firstName')]}>
                    <label htmlFor={'firstName'}>Vorname *</label>
                    {!usePrefix && formik.errors.firstName && formik.touched.firstName ?
                        <div className={'errors'}>{formik.errors.firstName}</div>
                        : null}
                    {usePrefix &&
                    formik.errors.alternativeLegalRepresentativeFirstName &&
                    formik.touched.alternativeLegalRepresentativeFirstName ?
                        <div className={'errors'}>{formik.errors.alternativeLegalRepresentativeFirstName}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'firstName')}
                        value={formik.values[applyPrefixedName(usePrefix, 'firstName')]}
                        className={
                            !usePrefix && formik.errors.firstName && formik.touched.firstName ?
                                'error' : usePrefix &&
                                formik.errors.alternativeLegalRepresentativeFirstName &&
                                formik.touched.alternativeLegalRepresentativeFirstName ?
                                    'error' : ''
                        }
                        placeholder={usePrefix ? 'Vorname' : 'Vorname/n'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'lastName')]}>
                    <label htmlFor={'lastName'}>Nachname *</label>
                    {!usePrefix && formik.errors.lastName && formik.touched.lastName ?
                        <div className={'errors'}>{formik.errors.lastName}</div>
                        : null}
                    {usePrefix &&
                        formik.errors.alternativeLegalRepresentativeLastName &&
                        formik.touched.alternativeLegalRepresentativeLastName ?
                        <div className={'errors'}>{formik.errors.alternativeLegalRepresentativeLastName}</div>
                        : null }
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'lastName')}
                        value={formik.values[applyPrefixedName(usePrefix, 'lastName')]}
                        className={
                            !usePrefix && formik.errors.lastName && formik.touched.lastName ?
                                'error' : usePrefix &&
                                formik.errors.alternativeLegalRepresentativeLastName &&
                                formik.touched.alternativeLegalRepresentativeLastName ?
                                    'error' : ''
                        }
                        placeholder={usePrefix ? 'Nachname' : 'Nachname/n'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'street')]}>
                    <label htmlFor={'street'}>Strasse { !usePrefix ? <span>*</span> : ''} </label>
                    {!usePrefix && formik.errors.street && formik.touched.street ?
                        <div className={'errors'}>{formik.errors.street}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'street')}
                        value={formik.values[applyPrefixedName(usePrefix, 'street')]}
                        className={
                            !usePrefix && formik.errors.street &&
                            formik.touched.street ?
                                'error' :
                                ''
                        }
                        placeholder={'Strasse'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'streetNumber')]}>
                    <label htmlFor={'streetNumber'}>Strassennummer { !usePrefix ? <span>*</span> : ''}</label>
                    {!usePrefix && formik.errors.streetNumber && formik.touched.streetNumber ?
                        <div className={'errors'}>{formik.errors.streetNumber}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'streetNumber')}
                        value={formik.values[applyPrefixedName(usePrefix, 'streetNumber')]}
                        className={
                            !usePrefix && formik.errors.streetNumber &&
                            formik.touched.streetNumber ?
                                'error' :
                                ''
                        }
                        placeholder={'Strassennummer'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'zip')]}>
                    <label htmlFor={'zip'}>PLZ { !usePrefix ? <span>*</span> : ''}</label>
                    { !usePrefix && formik.errors.zip && formik.touched.zip ?
                        <div className={'errors'}>{formik.errors.zip}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'zip')}
                        value={formik.values[applyPrefixedName(usePrefix, 'zip')]}
                        className={
                            !usePrefix && formik.errors.zip &&
                            formik.touched.zip ?
                                'error' :
                                ''
                        }
                        placeholder={'PLZ'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'city')]}>
                    <label htmlFor={'city'}>Ort { !usePrefix ? <span>*</span> : ''}</label>
                    {!usePrefix && formik.errors.city && formik.touched.city ?
                        <div className={'errors'}>{formik.errors.city}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'city')}
                        value={formik.values[applyPrefixedName(usePrefix, 'city')]}
                        className={
                            !usePrefix && formik.errors.city &&
                            formik.touched.city ?
                                'error' :
                                ''
                        }
                        placeholder={'Ort'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'mobile')]}>
                    <label htmlFor={'mobile'}>Telefonnummer (Mobil) { !usePrefix ? <span>*</span> : ''}</label>
                    {!usePrefix && formik.errors.mobile && formik.touched.mobile ?
                        <div className={'errors'}>{formik.errors.mobile}</div>
                        : null}
                    <input
                        type={'text'}
                        name={applyPrefixedName(usePrefix, 'mobile')}
                        value={formik.values[applyPrefixedName(usePrefix, 'mobile')]}
                        className={
                            !usePrefix && formik.errors.mobile &&
                            formik.touched.mobile ?
                                'error' :
                                ''
                        }
                        placeholder={'Telefonnummer (Mobil)'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
                {!usePrefix &&
                    <FormGuide html={formTextIndexMapping.phone}>
                        <label htmlFor={'phone'}>Telefonnummer (Festnetz)</label>
                        {!usePrefix && formik.errors.phone && formik.touched.phone ?
                            <div className={'errors'}>{formik.errors.phone}</div>
                            : null}
                        <input
                            type={'text'}
                            name={'phone'}
                            value={formik.values.phone}
                            className={
                                formik.errors.phone &&
                                formik.touched.phone ?
                                    'error' :
                                    ''
                            }
                            placeholder={'Telefonnummer (Festnetz)'}
                            onChange={formik.handleChange}
                        />
                    </FormGuide>
                }
                <FormGuide html={formTextIndexMapping[applyPrefixedName(usePrefix, 'email')]}>
                    <label htmlFor={'email'}>E-Mail-Adresse { !usePrefix ? <span>*</span> : ''}</label>
                    {!usePrefix && formik.errors.email && formik.touched.email ?
                        <div className={'errors'}>{formik.errors.email}</div>
                        : null}
                    <input
                        type={'email'}
                        name={applyPrefixedName(usePrefix, 'email')}
                        value={formik.values[applyPrefixedName(usePrefix, 'email')]}
                        className={
                            !usePrefix && formik.errors.email &&
                            formik.touched.email ?
                                'error' :
                                ''
                        }
                        placeholder={'E-Mail-Adresse'}
                        onChange={formik.handleChange}
                    />
                </FormGuide>
            </>
        );
    }

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Gesetzliche Vertretung / Korrespondenzadresse: {userName}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <div>
                                    <strong>Familie oder 1. Person</strong>
                                    {getPersonInputs(false)}
                                    <div className={'checkbox-with-label'}>
                                        <input
                                            type={'checkbox'}
                                            name={'hasAlternativeLegalRepresentative'}
                                            id={'hasAlternativeLegalRepresentative'}
                                            checked={formik.values.hasAlternativeLegalRepresentative}
                                            onChange={formik.handleChange}
                                        />
                                        <label
                                            htmlFor={'hasAlternativeLegalRepresentative'}
                                        >
                                            Weitere gesetzliche Vertretung (optional)
                                        </label>
                                    </div>
                                    {formik.values.hasAlternativeLegalRepresentative &&
                                        <>
                                            <div>
                                                <strong>2. Person</strong>
                                            </div>

                                            {getPersonInputs(true)}
                                        </>
                                    }
                                </div>
                                <button
                                    type={'submit'}
                                    className={'submit button primary margin-top-2'}
                                >
                                    Weiter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

LegalRepresentativeForm.propTypes = {
    service: PropTypes.any
}
