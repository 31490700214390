'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';
import Loader        from '../loader';
import {DataOverviewForm} from '../dataOverviewForm';

export const DataOverviewContainer = ({applicationFormService}) => {
    const dataOverviewMachine = applicationFormService.children.get('dataOverviewForm');

    const isLoading = useSelector(
        dataOverviewMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <DataOverviewForm
                    service={dataOverviewMachine}
                />
            }
        </>
    )
};

DataOverviewContainer.propTypes = {
    applicationFormService: PropTypes.any
}
