'use strict';

import React, {useEffect, useState}   from 'react';
import PropTypes                      from 'prop-types';
import {useActor, useSelector}        from '@xstate/react';
import {StudentContainer}             from '../studentContainer';
import {LegalRepresentativeContainer} from '../legalRepresentativeContainer';
import {SchoolGoalContainer}          from '../schoolGoalContainer';
import {CurrentSchoolContainer}       from '../currentSchoolContainer';
import {ClassTeacherContainer}        from '../classTeacherContainer';
import {DocumentUploadContainer}      from '../documentUploadContainer';
import {DataOverviewContainer}        from '../dataOverviewContainer';
import {ResumeFormContainer}          from '../resumeFormContainer';
import {ProgressBar}                  from '../progressBar';
import Loader                         from '../loader';
import {ChangePassword}               from '../changePassword';

export const FormContainer = ({applicationMachine}) => {

    const applicationFormService = applicationMachine.children.get('applicationForm');
    const [state, send] = useActor(applicationFormService);

    const [currentStepNumber, setCurrentStepNumber] = useState(1);

    const isLoading = useSelector(applicationFormService, state => {
        return state.matches('getSalutations') ||
            state.matches('getLanguages') ||
            state.matches('getCountries')
    });

    const inStudentStep = useSelector(applicationFormService, state => (
        state.matches('studentData.editing'))
    );
    const inParentsStep = useSelector(applicationFormService, state => (
        state.matches('parentsData.editing'))
    );
    const inSchoolGoalStep = useSelector(applicationFormService, state => (
        state.matches('schoolGoalData.editing'))
    );
    const inCurrentSchoolStep = useSelector(applicationFormService, state => (
        state.matches('currentSchoolData.editing'))
    );
    const inClassTeacherStep = useSelector(applicationFormService, state => (
        state.matches('teacherData.editing'))
    );
    const inDocumentUploadStep = useSelector(applicationFormService, state => (
        state.matches('documentsUpload.editing'))
    )
    const inDataOverviewStep = useSelector(applicationFormService, state => (
        state.matches('dataOverview.editing'))
    );
    const inResumeFormStep = useSelector(applicationFormService, state => state.matches('resumeFormOnLogin'));
    const inChangePassword = useSelector(applicationFormService, state => (
        state.matches('changePassword.edit') ||
        state.matches('changePassword.changePasswordSuccess') ||
        state.matches('changePassword.changePasswordError')
    ))

    useEffect(() => {
        switch (true) {
            case inStudentStep:
                setCurrentStepNumber(1);
                break;
            case inParentsStep:
                setCurrentStepNumber(2);
                break;
            case inSchoolGoalStep:
                setCurrentStepNumber(3);
                break;
            case inCurrentSchoolStep:
                setCurrentStepNumber(4);
                break;
            case inClassTeacherStep:
                setCurrentStepNumber(5);
                break;
            case inDocumentUploadStep:
                setCurrentStepNumber(6);
                break;
            case inDataOverviewStep:
                setCurrentStepNumber(7);
                break;
            default:
                setCurrentStepNumber(0);
                break;
        }
    }, [
        inClassTeacherStep,
        inCurrentSchoolStep,
        inDataOverviewStep,
        inParentsStep,
        inSchoolGoalStep,
        inStudentStep,
        inDocumentUploadStep
    ]);

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading && !inResumeFormStep && !inChangePassword &&
                <ProgressBar
                    valueMax={state.context.stepsCount}
                    valueMin={1}
                    valueNow={currentStepNumber}
                    hasBackLink={() => state.can({type: 'BACK'})}
                    onBackClick={() => send('BACK')}
                />
            }
            {inResumeFormStep &&
                <ResumeFormContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inStudentStep &&
                <StudentContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inParentsStep &&
                <LegalRepresentativeContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inSchoolGoalStep &&
                <SchoolGoalContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inCurrentSchoolStep &&
                <CurrentSchoolContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inClassTeacherStep &&
                <ClassTeacherContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inDocumentUploadStep &&
                <DocumentUploadContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inDataOverviewStep &&
                <DataOverviewContainer
                    applicationFormService={applicationFormService}
                />
            }
            {inChangePassword &&
                <ChangePassword
                    service={applicationFormService}
                />
            }
        </>
    )
};

FormContainer.propTypes = {
    applicationMachine: PropTypes.any
}
