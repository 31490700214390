'use strict';

import React      from 'react';
import PropTypes  from 'prop-types';
import {useActor} from '@xstate/react';
import parse      from 'html-react-parser'

import {DataOverview} from '../dataOverview';
import {ResetDataModal} from '../resetDataModal';
import {useTranslation} from 'react-i18next';

export const RegistrationCompleteContainer = ({service}) => {
    const [state, send] = useActor(service);
    const {t} = useTranslation();

    const {
        user,
        registrationData,
        texts = [],
        showResetRegistrationButton = false
    } = state.context;

    const {schoolGoal = {}} = registrationData;

    const {targetSchool = {}} = schoolGoal;

    const {
        examData = '',
        examPlace = ''
    } = targetSchool;

    const arrowSvg = '<svg stroke="currentColor" ' +
        'fill="currentColor" stroke-width="0" ' +
        'viewBox="0 0 448 512" height="1em" ' +
        'width="1em" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c' +
        '9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 ' +
        '9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.' +
        '4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 ' +
        '101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'

    let examList = texts[1].replaceAll('<li>', '<li>' + arrowSvg);
    const resetDataHelpText = showResetRegistrationButton && texts[2] ?
        texts[2].replaceAll('<li>', `<li>${arrowSvg}`) :
        '';

    return (
        <>
            <ResetDataModal authService={service} />
            <div className={'grid-container grid-y resumeFormContainer'}>
                <div>
                    <div className={'overview-title'}>

                        {showResetRegistrationButton &&
                            <section className={'reset-data-section'}>
                                {parse(resetDataHelpText)}

                                <button
                                    type={'button'}
                                    className={'submit button primary margin-top-2 margin-bottom-2'}
                                    onClick={() => send({
                                        type: 'RESETUSERDATA'
                                    })}
                                >
                                    {t('resetDataCTAText')}
                                </button>
                            </section>
                        }

                        <h1>
                            <strong>Vielen Dank für Ihre Anmeldung</strong>
                        </h1>
                        {texts[0] && texts[0] !== '' &&
                            <section className="overview-text">
                                {parse(texts[0])}
                            </section>
                        }
                        <button
                            type={'button'}
                            className={'submit button primary margin-top-2 margin-bottom-2'}
                            onClick={() => send({
                                type: 'DOCUMENTSUPLOAD'
                            })}
                        >
                            Zusätzliche Dokumente hochladen
                        </button>

                        <div className='grid-x exam-infos'>
                            {examData &&
                                <div className='cell auto'>
                                    <div className={'exam-info-box'}>
                                        {parse(examData)}
                                    </div>
                                </div>
                            }

                            {examPlace &&
                                <div className="cell auto box">
                                    <div className={'exam-info-box'}>
                                        {parse(examPlace)}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={'exam-list margin-top-3'}>
                            {texts[1] && texts[1] !== '' &&
                                parse(examList)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DataOverview
                user={user}
                registrationData={registrationData}
                formText={texts.length ? [texts[texts.length - 1]] : []}
            />
        </>
    )
};

RegistrationCompleteContainer.propTypes = {
    service: PropTypes.any
}
