'use strict';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';
import {send}    from 'xstate';

const {createMachine, assign} = require('xstate');

const dataOverviewMachine = createMachine({
    id: 'dataOverviewForm',
    predictableActionArguments: true,
    initial: 'edit',
    context: {
        user: {},
        registrationData: {},
        error: {
            data: {}
        },
        userName: ''
    },
    states: {
        edit: {
            on: {
                SUBMIT: {
                    target: 'saveData',
                    actions: assign({
                        registrationData: ({registrationData}, event) => ({
                            ...registrationData,
                            agbAccepted: event.agbAccepted || false
                        })
                    })
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({user}) => {
                        return api.userByIdApiUrlTemplate(user.id)
                    },
                    method: 'PUT',
                    data: ({user, registrationData}) => {
                        registrationData.complete = 1;
                        user.schoolApplication = registrationData;
                        return user;
                    }
                },
                onDone: {
                    actions: [
                        assign({
                            user: (_, {data}) => data.response,
                            registrationData: (_, {data}) => data.response.schoolApplication
                        }),
                        send('DONE')
                    ]
                },
                onError: {
                    target: 'edit',
                    actions: assign({
                        error: (_, event) => event
                    })
                }
            },
            on: {
                DONE: [
                    {
                        target: 'done',
                        cond: ({registrationData}) => {
                            return !!registrationData.agbAccepted === true
                        }
                    },
                    {
                        target: 'edit'
                    }
                ]
            }
        },
        done: {
            type: 'final',
            data: ({registrationData}) => registrationData
        }
    }
});

export default dataOverviewMachine;
