'use strict';

import React            from 'react';
import DatePicker       from 'react-datepicker';
import {registerLocale} from 'react-datepicker';
import de               from 'date-fns/locale/de';
import PropTypes        from 'prop-types';

export const DatePickerField = ({...props}) => {
    registerLocale('de', de);

    return (
        <DatePicker
            {...props}
            dateFormat={'dd.MM.yyyy'}
            selected={(props.value && new Date(props.value)) || null}
            onChange={val => props.onChange(val)}
            locale={'de'}
            peekNextMonth
            showYearDropdown
            yearDropdownItemNumber={10}
            placeholder={'TT.MM.JJJJ'}
        />
    );
};

DatePickerField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape()
}

export default DatePickerField;
