'use strict';

import React             from 'react';
import PropTypes         from 'prop-types';
import {FormGuide}       from '../formGuide';
import { AiOutlineFile } from 'react-icons/ai'

export const DataOverview = (
    {
        user = {
            salutation: {}
        },
        registrationData = {},
        formText = [],
        userName = '',
        children = ''
    }
) => {

    const {
        schoolGoal = {},
        classTeacher = {
            salutation: {}
        },
        currentSchool = {},
        legalRepresentative = {
            salutation: {}
        },
        student = {
            country: {},
            language: {},
            healthProblemsFile: []
        },
        documentsUpload = {
            documents: []
        }
    } = registrationData;

    let birthday;
    if (student.birthday && student.birthday !== '') {
        try {
            const date = new Date(student.birthday);
            birthday = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        } catch (e) {
            birthday = '';
        }
    }

    const {alternativeLegalRepresentative = []} = legalRepresentative;

    const isAlternativeRepresentativeValueEmpty = (fields) => {
        if (!Array.isArray(fields)) {
            fields = [fields];
        }

        let isEmpty = true;
        fields.forEach(field => {
            if (!isEmpty) {
                return; // continue
            }

            isEmpty = field === '';
        });

        return isEmpty;
    };

    const {
        specification = [],
        talent = [],
        targetSchool = {},
        targetSchoolCity = {},
    } = schoolGoal;

    const getDocumentsOutput = () => {

        let documents = documentsUpload.documents;
        if (!Array.isArray(student.healthProblemsFile)) {
            documents = [student.healthProblemsFile].concat(documentsUpload.documents);
        }
        return documents.map(document => {
            return (
                <div key={document.id}>
                    <AiOutlineFile/>
                    <span className="title">
                        {document.file_name}
                    </span>
                </div>
            );
        });
    }

    return (
        <div className={'grid-container grid-y resumeFormContainer'}>
            <div>
                <div className={'overview-title'}>
                    <h1>
                        <strong>Ihre Angaben im Überblick{userName !== '' ? `: ${userName}` : ''}</strong>
                    </h1>
                </div>
                <div className={'grid-x grid-margin-x resumeForm'}>
                    <div className={'grid-y cell auto'}>
                        <div>
                            <section>
                                <FormGuide
                                    html={formText[0] ? formText[0] : ''}
                                >
                                    <h2>Mein schulisches Ziel</h2>
                                </FormGuide>
                                <div>
                                    <small>Zielschule</small>
                                    <p>{targetSchool.name}</p>
                                </div>
                                <div>
                                    <small>Prüfungsort</small>
                                    <p>{targetSchoolCity.name}</p>
                                </div>
                                <div>
                                    <small>Ausprägung</small>
                                    <p>{Array.isArray(specification) ? '-' : specification.name}</p>
                                </div>
                                <div>
                                    <small>Zusatz Talenta</small>
                                    <p>{Array.isArray(talent) ? '-' : talent.name}</p>
                                </div>
                            </section>
                            <section>
                                <h2>Gesetzliche:r Vertreter:in</h2>
                                <div className={'grid-x'}>
                                    <div className={'cell auto'}>
                                        <h2>1. Familie oder 1. Person</h2>
                                        <div>
                                            <small>Anrede</small>
                                            <p>{legalRepresentative.salutation.name}</p>
                                        </div>
                                        <div>
                                            <small>Vorname/n</small>
                                            <p>{legalRepresentative.firstName}</p>
                                        </div>
                                        <div>
                                            <small>Nachname/n</small>
                                            <p>{legalRepresentative.lastName}</p>
                                        </div>
                                        <div>
                                            <small>Strasse, Nr</small>
                                            <p>{legalRepresentative.street}
                                                {legalRepresentative.streetNumber}</p>
                                        </div>
                                        <div>
                                            <small>PLZ, Ort</small>
                                            <p>{legalRepresentative.zip} {legalRepresentative.city}</p>
                                        </div>
                                        <div>
                                            <small>Telefonnummer (Mobil)</small>
                                            <p>{legalRepresentative.mobile}</p>
                                        </div>
                                        <div>
                                            <small>Telefonnummer (Festnetz)</small>
                                            <p>{legalRepresentative.phone ? legalRepresentative.phone : '-'}</p>
                                        </div>
                                        <div>
                                            <small>E-Mail-Adresse</small>
                                            <p>{legalRepresentative.email}</p>
                                        </div>
                                    </div>
                                    {!Array.isArray(alternativeLegalRepresentative) &&
                                        <>
                                            <div className={'cell auto second-person-info'}>
                                                <h2>2. Person</h2>
                                                <div>
                                                    <small>Anrede</small>
                                                    <p>{alternativeLegalRepresentative.salutation.name}</p>
                                                </div>
                                                <div>
                                                    <small>Vorname</small>
                                                    <p>{alternativeLegalRepresentative.firstName}</p>
                                                </div>
                                                <div>
                                                    <small>Nachname</small>
                                                    <p>{alternativeLegalRepresentative.lastName}</p>
                                                </div>
                                                <div>
                                                    <small>Strasse, Nr</small>
                                                    {
                                                        !isAlternativeRepresentativeValueEmpty([
                                                            alternativeLegalRepresentative.street,
                                                            alternativeLegalRepresentative.streetNumber
                                                        ]) ? // eslint-disable-next-line max-len
                                                            <p>{alternativeLegalRepresentative.street} {alternativeLegalRepresentative.streetNumber}</p> :
                                                            <p>-</p>
                                                    }
                                                </div>
                                                <div>
                                                    <small>PLZ, Ort</small>
                                                    {
                                                        !isAlternativeRepresentativeValueEmpty([
                                                            alternativeLegalRepresentative.zip,
                                                            alternativeLegalRepresentative.city
                                                        ]) ? // eslint-disable-next-line max-len
                                                            <p>{alternativeLegalRepresentative.zip} {alternativeLegalRepresentative.city}</p> :
                                                            <p>-</p>
                                                    }
                                                </div>
                                                <div>
                                                    <small>Telefonnummer (Mobil)</small>
                                                    {
                                                        !isAlternativeRepresentativeValueEmpty([
                                                            alternativeLegalRepresentative.mobile
                                                        ]) ?
                                                            <p>{alternativeLegalRepresentative.mobile}</p> :
                                                            <p>-</p>
                                                    }
                                                </div>
                                                <div>
                                                    <small>E-Mail-Adresse</small>
                                                    {
                                                        !isAlternativeRepresentativeValueEmpty([
                                                            alternativeLegalRepresentative.email
                                                        ]) ?
                                                            <p>{alternativeLegalRepresentative.email}</p> :
                                                            <p>-</p>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </section>
                            <section>
                                <h2>Schüler:in</h2>
                                {student.useStudentData &&
                                    <>
                                        <div>
                                            <small>Anrede</small>
                                            <p>{student.salutation.name}</p>
                                        </div>
                                        <div>
                                            <small>Vorname</small>
                                            <p>{student.firstName}</p>
                                        </div>
                                        <div>
                                            <small>Nachname</small>
                                            <p>{student.lastName}</p>
                                        </div>
                                        <div>
                                            <small>Sozialversicherungsnr.</small>
                                            <p>{student.socialSecurityNumber}</p>
                                        </div>
                                        <div>
                                            <small>E-Mail-Adresse</small>
                                            <p>{student.email}</p>
                                        </div>
                                    </>
                                }
                                {!student.useStudentData &&
                                    <>
                                        <div>
                                            <small>Anrede</small>
                                            <p>{user.salutation.name}</p>
                                        </div>
                                        <div>
                                            <small>Vorname</small>
                                            <p>{user.firstName}</p>
                                        </div>
                                        <div>
                                            <small>Nachname</small>
                                            <p>{user.lastName}</p>
                                        </div>
                                        <div>
                                            <small>Sozialversicherungsnr.</small>
                                            <p>{user.socialSecurityNumber}</p>
                                        </div>
                                        <div>
                                            <small>E-Mail-Adresse</small>
                                            <p>{user.email}</p>
                                        </div>
                                    </>
                                }
                                <div>
                                    <small>Geburtsdatum</small>
                                    <p>{birthday !== '' ? birthday : '-'}</p>
                                </div>
                                <div>
                                    <small>Nationalität</small>
                                    <p>{student.country.name}</p>
                                </div>
                                {parseInt(student.country.id) === 1 &&
                                    <div>
                                        <small>Heimatort</small>
                                        <p>{student.hometown !== '' ? student.hometown : '-'}</p>
                                    </div>
                                }
                                <div>
                                    <small>Muttersprache</small>
                                    <p>{student.language.name}</p>
                                </div>
                                <div>
                                    <small>Telefonnummer (Mobil)</small>
                                    <p>{student.mobile}</p>
                                </div>
                                <div>
                                    <small>Gewichtige gesundheitliche Probleme</small>
                                    <p>{documentsUpload.hasHealthProblems ? documentsUpload.healthProblems : '-'}</p>
                                </div>
                            </section>
                            <section>
                                <h2>Aktuell besuchte Schule</h2>
                                <div>
                                    <small>Aktuell besuchte Schule</small>
                                    <p>{currentSchool.schoolType}</p>
                                </div>
                                {currentSchool.schoolType === 'Name des Schulhauses' &&
                                    <>
                                        <div>
                                            <small>Name der Schule</small>
                                            <p>{currentSchool.schoolName}</p>
                                        </div>
                                        <div>
                                            <small>Sekundarschulgemeinde</small>
                                            <p>{currentSchool.schoolMunicipality}</p>
                                        </div>
                                        <div>
                                            <small>Aktuelle Klasse</small>
                                            <p>{currentSchool.classYears}</p>
                                        </div>
                                    </>
                                }
                                {currentSchool.schoolType !== 'Name des Schulhauses' &&
                                    <>
                                        <div>
                                            <small>Name der Schule</small>
                                            <p>{currentSchool.schoolOtherName}</p>
                                        </div>
                                        <div>
                                            <small>Sekundarschulgemeinde</small>
                                            <p>{currentSchool.schoolOtherMunicipality}</p>
                                        </div>
                                        <div>
                                            <small>Aktuelle Klasse</small>
                                            <p>{currentSchool.classOtherYears}</p>
                                        </div>
                                    </>
                                }
                            </section>
                            <section>
                                <h2>Meine Klassenlehrperson</h2>
                                <div>
                                    <small>Anrede</small>
                                    <p>{classTeacher.salutation.name}</p>
                                </div>
                                <div>
                                    <small>Vorname</small>
                                    <p>{classTeacher.firstName}</p>
                                </div>
                                <div>
                                    <small>Nachname</small>
                                    <p>{classTeacher.lastName}</p>
                                </div>
                                <div>
                                    <small>Strasse, Nr.</small>
                                    <p>{classTeacher.street} {classTeacher.streetNumber}</p>
                                </div>
                                <div>
                                    <small>PLZ, Ort</small>
                                    <p>{classTeacher.zip} {classTeacher.city}</p>
                                </div>
                                <div>
                                    <small>Telefon (Mobile)</small>
                                    <p>{classTeacher.mobile}</p>
                                </div>
                                <div>
                                    <small>E-Mail-Adresse</small>
                                    <p>{classTeacher.email}</p>
                                </div>
                            </section>
                            <section>
                                <h2>Beigefügte Dokumente</h2>
                                {getDocumentsOutput().length === 0 &&
                                    <div>-</div>
                                }
                                {getDocumentsOutput()}
                            </section>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

DataOverview.propTypes = {
    user: PropTypes.shape(),
    registrationData: PropTypes.shape(),
    formText: PropTypes.array,
    children: PropTypes.any,
    userName: PropTypes.string
};
