'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';
import Loader        from '../loader';
import {CurrentSchoolForm} from '../currentSchoolForm';

export const CurrentSchoolContainer = ({applicationFormService}) => {
    const currentSchoolMachine = applicationFormService.children.get('currentSchoolForm');

    const isLoading = useSelector(
        currentSchoolMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <CurrentSchoolForm
                    service={currentSchoolMachine}
                />
            }
        </>
    )
};

CurrentSchoolContainer.propTypes = {
    applicationFormService: PropTypes.any
}
