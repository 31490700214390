'use strict';

import React                   from 'react';
import PropTypes               from 'prop-types';
import {useActor, useSelector} from '@xstate/react';
import {useFormik}             from 'formik';
import {object, boolean}       from 'yup';
import {useTranslation}        from 'react-i18next';

import {DataOverview} from '../dataOverview';

export const DataOverviewForm = ({service}) => {
    const [state, send] = useActor(service);
    const {
        user,
        registrationData,
        texts: formText,
        userName,
        error: errorObject = {}
    } = state.context;
    const {t} = useTranslation();

    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const editing = useSelector(service, state => state.matches('edit'));
    const submitting = useSelector(service, state => state.matches('saveData'));

    const payload = {
        agbAccepted: false
    };

    const validationSchema = object({
        agbAccepted: boolean()
            .required('Sie müssen zuerst die Bedingungen akzeptieren')
            .oneOf([
                true,
                null
            ], 'Sie müssen zuerst die Bedingungen akzeptieren')
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return send(
                {
                    type: 'SUBMIT',
                    ...values
                }
            );
        }
    });

    return (
        <DataOverview
            user={user}
            registrationData={registrationData}
            formText={formText}
            userName={userName}
            errorCodes={errorCodes}
        >
            <section className={'agb'}>
                <form onSubmit={formik.handleSubmit}>
                    {
                        formik.errors.agbAccepted &&
                        formik.touched.agbAccepted ?
                            <div className={'errors'}>
                                {formik.errors.agbAccepted}
                            </div>
                            : null
                    }

                    {errorCodes &&
                        <div className={'errors'}>
                            {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                        </div>
                    }

                    <div className={'checkbox-with-label'}>
                        <input
                            type={'checkbox'}
                            name={'agbAccepted'}
                            onChange={formik.handleChange}
                            id={'agbAccepted'}
                            disabled={!editing}
                            className={
                                formik.errors.agbAccepted &&
                                formik.touched.agbAccepted ?
                                    'error' :
                                    ''
                            }
                        />
                        <label
                            htmlFor={'agbAccepted'}
                            className={
                                formik.errors.agbAccepted &&
                                formik.touched.agbAccepted ?
                                    'error' :
                                    ''
                            }
                        >
                            Die Anmeldung erfolgt mit Zustimmung der gesetzlichen Vertretung
                        </label>
                    </div>
                    {submitting &&
                        <div>
                            {t('submitting data')}
                        </div>
                    }
                    <button
                        type={'submit'}
                        className={'submit button primary margin-top-2'}
                        disabled={!editing}
                    >
                        jetzt Abschliessen
                    </button>
                </form>
            </section>
        </DataOverview>
    )
};

DataOverviewForm.propTypes = {
    service: PropTypes.any
};
