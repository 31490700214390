'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet, redirect,
    Route, RouterProvider
}                    from 'react-router-dom';

import Loader                                        from '../loader';
import {VerifyEmail}                                 from '../verifyEmail';
import {FormContainer}                               from '../formContainer';
import {RegistrationCompleteContainer}               from '../registrationCompleteContainer';
import {RegistrationCompleteDocumentUploadContainer} from '../registrationCompleteDocumentUploadContainer';
import {ChangePassword}                              from '../changePassword';

export const AuthorizedContainer = ({authService}) => {
    const kttgApplicationMachine = authService.children.get('kttgApplication');

    const isLoading = useSelector(kttgApplicationMachine, state => state.matches('init'));
    const verifyEmail = useSelector(kttgApplicationMachine, state => state.matches('verifyEmail'));
    const documentsUpload = useSelector(kttgApplicationMachine, state => state.matches('documentsUpload.editing'));
    const changePassword = useSelector(kttgApplicationMachine, state => {
        return  state.matches('changePassword.edit') ||
                state.matches('changePassword.changePasswordSuccess') ||
                state.matches('changePassword.changePasswordError');
    });
    const registrationComplete = useSelector(kttgApplicationMachine, state => {
        return state.matches('registrationComplete.view') ||
            state.matches('registrationComplete.resetUserData')
    });
    const form = useSelector(kttgApplicationMachine, state => state.matches('form'));

    const loading = !verifyEmail && !documentsUpload && !registrationComplete && !form && !changePassword;

    const getRouteToRedirect = route => {

        // to prevent infinit loop prevent redirect while loading
        if (loading) {
            return '';
        }

        let url = '/application';

        if (form) {
            url = `${url}/form`;
            if (route === url) {
                return '';
            }

            return url;
        }

        if (
            verifyEmail ||
            documentsUpload ||
            changePassword ||
            registrationComplete
        ) {
            if (route === url) {
                return '';
            }

            return url;
        }

        return url;
    }

    const authorizedRoutes = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path={'/application'}
                    element={
                        <div>
                            <Outlet/>
                        </div>
                    }
                >
                    <Route
                        index
                        loader={() => {
                            const routeToRedirect = getRouteToRedirect('/application');
                            if (routeToRedirect !== '') {
                                return redirect(routeToRedirect);
                            }
                        }}
                        element={
                            <>
                                {registrationComplete &&
                                    <RegistrationCompleteContainer
                                        service={kttgApplicationMachine}
                                    />
                                }
                                {documentsUpload &&
                                    <RegistrationCompleteDocumentUploadContainer
                                        service={kttgApplicationMachine}
                                    />
                                }
                                {verifyEmail &&
                                    <VerifyEmail
                                        service={kttgApplicationMachine}
                                    />
                                }
                                {changePassword &&
                                    <ChangePassword
                                        service={kttgApplicationMachine}
                                    />
                                }
                            </>
                        }
                    />
                    <Route
                        path={'form'}
                        loader={() => {
                            const routeToRedirect = getRouteToRedirect('/application/form');
                            if (routeToRedirect !== '') {
                                return redirect(routeToRedirect);
                            }
                        }}
                        element={
                            form &&
                                <FormContainer
                                    applicationMachine={kttgApplicationMachine}
                                />
                        }
                    />
                </Route>
                <Route
                    path={'/*'}
                    loader={() => {
                        const routeToRedirect = getRouteToRedirect('/');
                        if (routeToRedirect !== '') {
                            return redirect(routeToRedirect);
                        }
                    }}
                    element={<Loader/>}
                />
            </>
        )
    );

    return (
        <>
            {
                isLoading &&
                <Loader/>
            }
            {
                !isLoading &&
                <RouterProvider router={authorizedRoutes}/>
            }
        </>
    );
};

AuthorizedContainer.propTypes = {
    authService: PropTypes.any
}
