'use strict';

import React, {useEffect, useState} from 'react';
import PropTypes                    from 'prop-types';
import Autosuggest from 'react-autosuggest';

/**
 *
 */
export const InputAdvancedSelect = (props) => {

    const [fieldIsHidden, toggleFieldHidden] = useState(props.value === props.initialValue);
    const [search, updateSearch] = useState('');
    const [suggestions, updateSuggestions] = useState([]);

    useEffect(() => {
        if (props.value && props.value !== props.initialValue) {
            const result = Object.values(props.suggestions).find(suggestion => suggestion.id === props.value);
            const search = result !== undefined ?
                result.name :
                props.value;
            updateSearch(search);
        }

        if (props.value === props.initialValue) {
            updateSearch('');
            updateSuggestions([]);
        }
    }, [props.value, props.suggestions, props.initialValue]);

    /**
     *
     * @param e
     */
    const toggleOptionalBlock = e => {
        e.preventDefault();
        toggleFieldHidden(!fieldIsHidden);
    }

    /**
     *
     * @param e
     */
    const hideOptionalBlock = e => {
        e.preventDefault();
        toggleFieldHidden(!fieldIsHidden);
    }

    /**
     *
     * @param e
     */
    const deleteFieldAndHideOptionalBlock = e => {
        props.onChange(e, props.name, props.initialValue);
        hideOptionalBlock(e);
    }

    /**
     *
     * @param event
     * @param newValue
     */
    const onInputChange = (event, {newValue}) => {
        event.preventDefault();
        updateSearch(newValue);
    }

    const hasError = props.error ? props.error[props.name] : false;
    let inputClasses = 'form-field';
    const error = hasError ? props.error[props.name] : '';

    if (hasError) {
        inputClasses += ' ' + 'error';
    }

    if (props.disabled) {
        inputClasses += ' ' + 'disabled';
    }

    let iconOffset = '';
    if (props.infoText) {
        iconOffset = 'iconOffset';
    }

    let renderOptionalFormLink = '';
    let renderLabel = '';
    let renderOptionalCloseButton = '';
    let optionalFieldClass = fieldIsHidden ? 'optional-form-field hidden' : 'optional-form-field',
        optionalLabelClass = fieldIsHidden ? '' : 'label__icon-hidden';

    if (props.optional) {
        renderOptionalFormLink = (
            <div
                className={`optional-form-link label-container ${optionalLabelClass}`}
                onClick={toggleOptionalBlock}>
                <h3>{props.label}</h3>
                <span className={'opt-flag ' + iconOffset}>
                    {'Optional'}
                </span>
            </div>
        );
        renderOptionalCloseButton = (
            <span
                className={'close-optional'}
                onClick={deleteFieldAndHideOptionalBlock}
            >
                <a href="javascript: void(0)">{'Delete field'}</a>
            </span>
        );

    } else {
        optionalFieldClass = 'optional-form-field';
        renderLabel = (
            <div className={'label-container'} data-equalize>
                { props.label && props.label.length > 0 &&
                    <label
                        className={'label ellipsis'}
                        htmlFor={props.id}
                    >
                        {props.label}
                        {
                            props.required === true &&
                            <span className={'required'}> *</span>
                        }
                    </label>
                }
            </div>
        );
    }

    const inputProps = {
        placeholder: props.pleaseChoose,
        value: search,
        onChange: onInputChange,
        onBlur: props.onBlur,
        name: props.name
    };

    const getSuggestionValue = suggestion => suggestion.name;

    const renderSuggestion = (suggestion) => {
        return <div className={'react-select__option'}>{getSuggestionValue(suggestion)}</div>;
    };

    const getSuggestions = options => {
        const inputValue = options.value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0 && props.showSuggestionsOnFocus) {
            if (options.reason && options.reason === 'input-focused') {
                return Object.values(props.suggestions).filter(Boolean)
            }
        }

        return Object.values(props.suggestions).map(({id, name}) => {
            return name.toLowerCase().indexOf(inputValue) >= 0 ?
                {name, id} :
                false;
        }).filter(Boolean)
    }


    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = options => {
        updateSuggestions(getSuggestions(options));
    }


    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        updateSuggestions([]);
    }


    const onSuggestionSelected = (event, {suggestion}) => {
        props.onChange({
            item: {
                id: suggestion.id
            }
        });
    }

    return (
        <div className={`comp-input-advanced-select ${inputClasses}`}>

            {renderOptionalFormLink}

            <div className={optionalFieldClass}>

                {renderLabel}

                <span className={`advanced-select-wrapper ${props.errorClass}`}>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        onSuggestionSelected={onSuggestionSelected}
                        shouldRenderSuggestions={() => true}
                        alwaysRenderSuggestions={false}
                    />
                    <span className={'error-message'}>{hasError ? error : ''}</span>
                </span>

                {renderOptionalCloseButton}

            </div>
        </div>
    );
};

InputAdvancedSelect.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    pleaseChoose: PropTypes.string.isRequired,
    enablePleaseChoose: PropTypes.bool,
    error: PropTypes.object,
    errorClass: PropTypes.string,
    optional: PropTypes.bool,
    infoText: PropTypes.string,
    sortByProperty: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    showSuggestionsOnFocus: PropTypes.bool,
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    suggestions: PropTypes.shape().isRequired
};

InputAdvancedSelect.defaultProps = {
    disabled: false,
    value: -1,
    initialValue: -1,
    optional: false,
    infoText: null,
    sortByProperty: 'name'
};
