'use strict';

import 'core-js/stable';
import React      from 'react';
import ReactDOM   from 'react-dom/client';
import es6Promise from 'es6-promise';

import App           from './components/app';
import ErrorBoundary from './components/error';
import Loader        from './components/loader';
import './components/i18n';

es6Promise.polyfill();

const app = ReactDOM.createRoot(
    document.getElementById('app')
);

app.render(
    <ErrorBoundary>
        <React.Suspense fallback={<Loader/>}>
            <App/>
        </React.Suspense>
    </ErrorBoundary>
);
