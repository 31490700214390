'use strict';

const cfg = {
    api: {
        baseUrl: '/api',
        userSingleApiUrlTemplate: () => '/api/user',
        userLoginApiUrlTemplate: () => '/login',
        userSignUpApiUrlTemplate: () => '/api/users',
        userByIdApiUrlTemplate: id => `/api/users/${id}`,
        requestResetPasswordApiUrlTemplate: () => '/api/users/password-reset-token',
        requestVerificationEmailApiUrlTemplate: () => '/api/users/request-verification-email',
        changePasswordApiUrlTemplate: () => '/api/users/change-password',
        changePasswordByUserApiUrlTemplate: () => '/api/users/change-password-by-user',
        resetUserDataApiUrlTemplate: () => '/api/users/reset-data',
        salutationsApiUrlTemplate: () => '/api/salutations',
        languagesApiUrlTemplate: () => '/api/languages',
        countriesApiUrlTemplate: () => '/api/countries',
        schoolGoalsPossibilitiesApiUrlTemplate: () => '/api/school-goals',
        schoolGoalSingleApiUrlTemplate: id => `/api/school-goal/${id}`,
        legalRepresentativeSingleApiUrlTemplate: id => `/api/legal-representative/${id}`,
        studentSingleApiUrlTemplate: id => `/api/student/${id}`,
        studentSingleDocumentApiUrlTemplate: id => `/api/student/document/${id}`,
        documentsUploadSingleApiUrlTemplate: id => `/api/documents-upload/${id}`,
        documentsUploadDocumentApiUrlTemplate: id => `/api/documents-upload/${id}/document`,
        documentsUploadDocumentSingleApiUrlTemplate:
            (id, documentId) => `/api/documents-upload/${id}/document/${documentId}`,
        currentSchoolSingleApiUrlTemplate: id => `/api/current-school/${id}`,
        classTeacherSingleApiUrlTemplate: id => `/api/class-teacher/${id}`,
        schoolApplicationSingleApiUrlTemplate: id => `/api/school-application/${id}`,
        formTextsSingleApiUrlTemplate: stepName => `/api/texts/${stepName}`,
        isResetButtonEnabledApiUrlTemplate: () => '/api/application-settings/reset-button-enabled'
    }
};

export default cfg;

export const {api} = cfg;
