'use strict';

import React            from 'react';
import PropTypes        from 'prop-types';
import {useSelector}    from '@xstate/react';
import Loader           from '../loader';
import {SchoolGoalForm} from '../schoolGoalForm';

export const SchoolGoalContainer = ({applicationFormService}) => {
    const schoolGoalMachine = applicationFormService.children.get('schoolGoalForm');

    const isLoading = useSelector(
        schoolGoalMachine,
        state => state.matches('init') || state.matches('resumeForm')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <SchoolGoalForm
                    schoolGoalMachine={schoolGoalMachine}
                />
            }
        </>
    )
};

SchoolGoalContainer.propTypes = {
    applicationFormService: PropTypes.any
}
