'use strict';

import React              from 'react';
import Logo               from '../../assets/images/logo.jpg';
import {GrFacebookOption} from 'react-icons/gr';
import {GrTwitter}        from 'react-icons/gr';
import {MdLocationOn}     from 'react-icons/md';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Footer = () => {

    return (
        <footer className="footer fullwidth">
            <h2 className="invis">Footer</h2>
            <div className="footer-content fullwidth">
                <div className="wrapper row vcard">
                    <div className="col-4">
                        <h3>Amt für Mittel- und Hochschulen</h3>
                        <p>
                            Das Amt ist verantwortlich für die Thurgauer Mittelschulen und ist zuständig für
                            Hochschulfragen sowie die Vergabe von Ausbildungsbeiträgen.
                        </p>
                    </div>
                    <div className="col-4">
                        <h3>Adresse</h3>
                        <p>
                            <span className="fn org">
                                Amt für Mittel- und Hochschulen
                            </span>
                            <br/>
                            <span className="adr">
                                <span className="street-address">
                                    Grabenstrasse 11
                                </span>
                                <br/>
                                <span className="region">
                                    TG &nbsp;
                                </span>
                                <span className="postal-code">
                                    8510
                                </span>
                                <span className="locality">
                                    &nbsp;Frauenfeld
                                </span>
                                <br/>
                                <span className="country-name">
                                    Schweiz
                                </span>
                                <a
                                    /*eslint-disable-next-line max-len */
                                    href="https://www.google.ch/maps/place/Grabenstrasse+11,+8500+Frauenfeld/@47.5569034,8.8948866,17z/data=!3m1!4b1!4m5!3m4!1s0x479a921f61bf6911:0x96a7ab97351c60d1!8m2!3d47.5569034!4d8.8970753"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MdLocationOn size={18} /> Lageplan
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="col-4">
                        <h3>Kontakt</h3>
                        <p>
                            <a href="http://www.kftg.ch" target={'_blank'} rel={'noreferrer'}>
                                www.kftg.ch
                            </a>
                            <br/>
                            <a href="https://www.ksk.ch" target={'_blank'} rel={'noreferrer'}>
                                www.ksk.ch
                            </a>
                            <br/>
                            <a href="https://www.ksr.ch" target={'_blank'} rel={'noreferrer'}>
                                www.ksr.ch
                            </a>
                            <br/>
                            <a href="https://www.pmstg.ch" target={'_blank'} rel={'noreferrer'}>
                                www.pmstg.ch
                            </a>
                            <br/>
                        </p>
                    </div>
                    <div className="col-4">
                        <h3>Öffnungszeiten</h3>
                        <p>
                            Montag bis Freitag
                            <br/>
                            08:00 – 11:30
                            <br/>
                            14:00 – 17:00
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-links wrapper row">
                <div className="col-4 footer-links-branding">
                    <div className={'logo'}>
                        <img src={Logo} width={300} height={103}></img>
                    </div>
                </div>
                <div className="col-4 footer-links-important">
                    <ul className="reset">
                        <li>
                            <a href="https://www.tg.ch">
                                Startseite tg.ch
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.tg.ch/kontakt.html/17"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-4 footer-links-diclaimer">
                    <ul className="reset">
                        <li>
                            <a href="https://www.tg.ch/impressum">
                                Impressum
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tg.ch/rechtliches">
                                Rechtliches
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tg.ch/barrierefreiheit">
                                Barrierefreiheit
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-4 footer-links-sm">
                    <ul className="reset">
                        <li>
                            <a
                                href="https://twitter.com/Kanton_Thurgau"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Kanton Thurgau auf Twitter"
                            >
                                <GrTwitter/>
                                <span className="invis">
                                    Twitter
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/kt.thurgau"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Kanton Thurgau auf Facebook"
                            >
                                <GrFacebookOption/>
                                <span className="invis">
                                    Facebook
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};
