'use strict';

import React, { useState }          from 'react';
import {useActor}                   from '@xstate/react';
import {
    Formik,
    Form,
    Field,
    ErrorMessage
}                                   from 'formik';
import PropTypes                    from 'prop-types';
import {
    string,
    object,
    ref
}                                   from 'yup';
import {AHV13}                      from 'ahv13-validator';
import passwordReveal               from '../../lib/passwordReveal'
import { FormGuide }                from '../formGuide';

import {password}       from '../conf';
import {useTranslation} from 'react-i18next';

const validateSSN = ssn => {
    if (!ssn) {
        return 'Sozialversicherungsnr. ist ein Pflichtfeld';
    }

    const ssNumberValidator = new AHV13();
    if (!ssNumberValidator.isValid(ssn)) {
        return 'Ungültige Sozialversicherungsnr.';
    }

    return '';
};

/**
 *
 * @param registrationService
 * @returns {JSX.Element}
 * @constructor
 */
export const RegistrationForm = ({registrationService}) => {
    const [state, sendRegister] = useActor(registrationService);
    const [inputType, setInputType] = useState('password');

    const {context} = state;
    const {registrationData} = context;
    // default values
    const {
        salutations = [
            {
                id: 1,
                name: 'Herr'
            },
            {
                id: 2,
                name: 'Frau'
            }
        ],
        texts: formText
    } = context;

    const payload = {
        salutation: registrationData.salutation || '',
        firstName: registrationData.firstName || '',
        lastName: registrationData.lastName || '',
        socialSecurityNumber: registrationData.socialSecurityNumber || '',
        email: registrationData.email || '',
        password: registrationData.password || '',
        passwordRepeat: registrationData.passwordRepeat || ''
    };
    const {t} = useTranslation();

    const validationSchema = object({
        salutation: string()
            .oneOf([
                ...salutations.map(({id}) => id),
                null
            ], 'Invalide Anrede')
            .required('Anrede ist ein Pflichtfeld'),
        firstName: string().required('Vorname ist ein Pflichtfeld'),
        lastName: string().required('Nachname ist ein Pflichtfeld'),
        email: string().email('Ungültige E-Mail').required('E-Mail ist ein Pflichtfeld'),
        password: string()
            .min(password.minLength, `Passwort muss mindestens ${password.minLength} lang sein`)
            .required('Passwort ist ein Pflichtfeld'),
        passwordRepeat: string()
            .required('Passwort wiederholen ist ein Pflichtfeld')
            .oneOf([ref('password'), null], 'Passwörter stimmen nicht überein')
    });


    const isSubmitting = state.matches('loading');

    const register = payload => {
        return sendRegister({
            type: 'SIGNUP',
            ...payload
        });
    }

    const {error: errorObject = {}} = context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = [],
        salutation: salutationError = '',
        firstName: firstNameError = '',
        lastName: lastNameError = '',
        socialSecurityNumber: socialSecurityNumberError = '',
        email: emailError = '',
        password: passwordError = [],
        passwordRepeat: passwordRepeatError = []
    } = codes;


    const getSalutationOptions = () => {
        return salutations.map(({id, name}) => {
            return <option key={id} value={id}>{name}</option>;
        });
    };

    return (
        <div>
            <Formik
                initialValues={payload}
                validationSchema={validationSchema}
                onSubmit={register}
            >
                {({errors, touched}) => {
                    return (
                        <Form>
                            <div>

                                {errorCodes &&
                                    <div className={'errors'}>
                                        {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                    </div>
                                }

                                <FormGuide html={formText[0] ? formText[0] : ''}>
                                    <label htmlFor={'salutation'}>Anrede</label>
                                    <ErrorMessage component='div' name='salutation' className='errors' />
                                    {
                                        salutationError !== '' ?
                                            <div className={'errors'}>{salutationError}</div> : null
                                    }
                                    <span className={'select-wrapper'}>
                                        <Field
                                            as={'select'}
                                            name={'salutation'}
                                            className={errors.salutation && touched.salutation ? 'error' : ''}
                                        >
                                            <option value={''} disabled={true}>- Bitte wählen -</option>
                                            {getSalutationOptions()}
                                        </Field>
                                    </span>
                                </FormGuide>

                                <FormGuide html={formText[1] ? formText[1] : ''}>
                                    <label htmlFor={'firstName'}>Vorname</label>
                                    <ErrorMessage component='div' name='firstName' className='errors' />
                                    {
                                        firstNameError !== '' ?
                                            <div className={'errors'}>{firstNameError}</div> : null
                                    }
                                    <Field
                                        name={'firstName'}
                                        type={'text'}
                                        className={errors.firstName && touched.firstName ? 'error' : ''}
                                    />
                                </FormGuide>

                                <FormGuide html={formText[2] ? formText[2] : ''}>
                                    <label htmlFor={'lastName'}>Nachname</label>
                                    <ErrorMessage component='div' name='lastName' className='errors' />
                                    {
                                        lastNameError !== '' ?
                                            <div className={'errors'}>{lastNameError}</div> : null
                                    }
                                    <Field
                                        name={'lastName'}
                                        type={'text'}
                                        className={errors.lastName && touched.lastName ? 'error' : ''}
                                    />
                                </FormGuide>

                                <FormGuide html={formText[3] ? formText[3] : ''}>
                                    <label htmlFor={'socialSecurityNumber'}>Sozialversicherungsnr.</label>
                                    <ErrorMessage component='div' name='socialSecurityNumber' className='errors' />
                                    {
                                        socialSecurityNumberError !== '' ?
                                            <div className={'errors'}>{socialSecurityNumberError}</div> : null
                                    }
                                    <Field
                                        name={'socialSecurityNumber'}
                                        type={'text'}
                                        validate={validateSSN}
                                        className={errors.socialSecurityNumber
                                        && touched.socialSecurityNumber ? 'error' : ''}
                                    />
                                </FormGuide>

                                <FormGuide html={formText[4] ? formText[4]: ''}>
                                    <label htmlFor={'email'}>E-Mail Adresse</label>
                                    <ErrorMessage component='div' name='email' className='errors' />
                                    {
                                        emailError !== '' ?
                                            <div className={'errors'}>{t(emailError)}</div> : null
                                    }
                                    <Field
                                        name={'email'}
                                        type={'email'}
                                        className={errors.email && touched.email || emailError ? 'error' : ''}
                                    />
                                </FormGuide>

                                <FormGuide html={formText[5] ? formText[5] : ''}>
                                    <label htmlFor={'password'}>Passwort</label>
                                    { passwordReveal(inputType, setInputType) }
                                    <ErrorMessage component='div' name='password' className='errors' />
                                    {
                                        passwordError.length ?
                                            <div className={'errors'}>{t(passwordError[0])}</div> : null
                                    }
                                    <Field
                                        name={'password'}
                                        type={inputType}
                                        id={'password'}
                                        className={
                                            (errors.password && touched.password) || passwordError.length ?
                                                'error' :
                                                ''
                                        }
                                    />
                                </FormGuide>

                                <FormGuide html={formText[6] ? formText[6] : ''}>
                                    <label htmlFor={'passwordRepeat'}>Passwort wiederholen</label>
                                    { passwordReveal(inputType, setInputType) }
                                    <ErrorMessage component='div' name='passwordRepeat' className='errors' />
                                    {
                                        passwordRepeatError.length ?
                                            <div className={'errors'}>{t(passwordRepeatError[0])}</div> : null
                                    }
                                    <Field
                                        name={'passwordRepeat'}
                                        type={inputType}
                                        className={
                                            (errors.passwordRepeat && touched.passwordRepeat) ||
                                                passwordRepeatError.length ?
                                                'error' :
                                                ''
                                        }
                                    />
                                </FormGuide>
                            </div>

                            <button
                                type={'submit'}
                                className={'submit button primary margin-top-2'}
                                disabled={isSubmitting}
                            >
                                Account erstellen
                            </button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

RegistrationForm.propTypes = {
    registrationService: PropTypes.shape()
};
