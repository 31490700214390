'use strict';

import React, { useState }     from 'react'
import {useActor, useSelector} from '@xstate/react';
import {
    Formik,
    Form,
    Field,
    ErrorMessage
}                              from 'formik';
import PropTypes        from 'prop-types';
import {
    string,
    object
}                       from 'yup';
import {password}       from '../conf';
import passwordReveal   from '../../lib/passwordReveal'
import { Link }         from 'react-router-dom'
import { FormGuide }    from '../formGuide';
import {useTranslation} from 'react-i18next';

/**
 *
 * @param authService
 * @returns {JSX.Element}
 * @constructor
 */
export const SignInForm = ({authService}) => {
    const [state, sendAuth] = useActor(authService);
    const [inputType, setInputType] = useState('password');
    const {t} = useTranslation();
    const {context} = state;

    const {
        error: errorObject = {},
        texts: formText = []
    } = context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const payload = {
        username: '',
        password: ''
    };

    const isSubmitting = useSelector(authService, state => state.matches('loading'));

    const validationSchema = object({
        username: string().email(t('wrongEmailFormat')).required(t('emailRequiredMessage')),
        password: string()
            .min(password.minLength, t('passwordLengthMessage', {length: password.minLength}))
            .required(t('passwordRequiredMessage'))
    });

    const signIn = payload => {
        return sendAuth({
            type: 'LOGIN',
            ...payload
        });
    }

    return (
        <div>
            <Formik
                initialValues={payload}
                validationSchema={validationSchema}
                onSubmit={signIn}
            >
                {({errors, touched}) => {
                    return (
                        <div className={'grid-container grid-y'}>
                            <div
                                className={[
                                    'small-auto',
                                    'large-padding-top_bottom',
                                    'medium-padding-top_bottom',
                                    'small-padding-top_bottom',
                                    'content-container'
                                ].join(' ')}>

                                <div className={'margin-bottom-3'}>
                                    <h1>
                                        <strong>{t('loginTitle')}</strong>
                                    </h1>
                                </div>

                                <div className={'grid-x grid-margin-x'}>
                                    <div className={'grid-y cell auto'}>
                                        <FormGuide html={formText[0] ? formText[0] : ''} hideMobileInfo={true}>
                                            <Form>
                                                <div className={'login-form'}>
                                                    <div className={'no-login'}>
                                                        <div className={'margin-bottom-1'}>
                                                            <strong>{t('dontHaveLogin')}</strong>
                                                        </div>
                                                        <Link to={'/registration'}>
                                                            {t('createAccount')}
                                                        </Link>
                                                    </div>

                                                    <div className={'margin-bottom-1'}>
                                                        <strong>{t('loginExists')}</strong>
                                                    </div>

                                                    {errorCodes &&
                                                        <div className={'errors'}>
                                                            {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                                        </div>
                                                    }

                                                    <div>
                                                        <label htmlFor={'username'}>{t('email')}</label>
                                                        <ErrorMessage 
                                                            component='div' className='errors' name={'username'}
                                                        />
                                                        <Field
                                                            id="username"
                                                            type="email"
                                                            name="username"
                                                            placeholder="E-Mail-Adresse"
                                                            className={errors.username && touched.username ?
                                                                'error' : ''}
                                                        />

                                                        <label htmlFor={'password'}>{t('password')}</label>
                                                        { passwordReveal(inputType, setInputType) }
                                                        <ErrorMessage
                                                            component='div' className='errors' name={'password'}/>
                                                        <Field
                                                            id="password"
                                                            name="password"
                                                            type={inputType}
                                                            placeholder="Passwort"
                                                            className={errors.password && touched.password ?
                                                                'error' : ''}
                                                        />
                                                    </div>

                                                    <div className={'login'}>
                                                        <Link
                                                            to={'/reset-password'}
                                                            onClick={() => {
                                                                sendAuth('RESETPASSWORD');
                                                            }}
                                                        >
                                                            {t('passwordForgotten')}
                                                        </Link>

                                                        <button
                                                            type="submit"
                                                            className="submit button primary"
                                                            disabled={isSubmitting}
                                                        >
                                                            {t('login')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </FormGuide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

SignInForm.propTypes = {
    authService: PropTypes.shape()
};
