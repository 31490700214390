'use strict';

const cfg = {
    // keep in sync with kttg-api/cnf/app.js
    password: {
        minLength: 8
    }
};

export default cfg;

export const {password} = cfg;
