'use strict';

const {createMachine, assign} = require('xstate');
const {context} = require('./lib/fetchMachineContext');
const {api} = require('./lib/conf');

const authMachine = createMachine({
    id: 'authorization',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        user: null,
        error: {
            data: {}
        },
        texts: [],
        message: ''
    },
    states: {
        init: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.userSingleApiUrlTemplate()
                },
                onDone: {
                    target: 'checkUserAuthorized',
                    actions: [
                        assign({
                            message: () => 'checking authorized',
                            user: (_, {data}) => data.response,
                            error: {
                                data: {}
                            }
                        })
                    ]
                },
                onError: {
                    actions: assign({
                        error: (_, {data}) => ({data})
                    }),
                    target: 'checkUserAuthorized'
                }
            }
        },
        unauthorized: {
            id: 'unauthorized',
            initial: 'getTexts',
            states: {
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('unauthorized')
                        },
                        onDone: {
                            target: 'edit',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'edit'
                        }

                    }
                },
                edit: {}
            },
            on: {
                LOGIN: {
                    target: 'loading'
                },
                VERIFYEMAIL: {
                    target: 'verifyEmail'
                },
                RESETPASSWORD: {
                    target: 'resetPassword'
                }
            }
        },
        checkUserAuthorized: {
            invoke: {
                src: ({user}) => callback => {
                    if (!user) {
                        const changePasswordUrlArray = window.location.pathname.split('/');
                        const changePasswordUrl = changePasswordUrlArray[0] === '' ?
                            changePasswordUrlArray[1] : changePasswordUrlArray[0];
                        if (changePasswordUrl === 'change-password') {
                            return callback('CHANGEPASSWORD');
                        }

                        switch (window.location.pathname) {
                            case '/reset-password':
                                return callback('RESETPASSWORD');
                            default:
                                return callback('UNAUTHORIZED');
                        }
                    }

                    return callback('AUTHORIZED');
                }
            },
            on: {
                VERIFYEMAIL: {
                    target: 'verifyEmail'
                },
                UNAUTHORIZED: {
                    target: 'unauthorized'
                },
                RESETPASSWORD: {
                    target: 'resetPassword'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                },
                AUTHORIZED: {
                    target: 'authorized'
                }
            }
        },
        verifyEmail: {},
        resetPassword: {
            entry: assign({
                error: {
                    data: {}
                }
            }),
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        REQUESTRESETPASSWORD: {
                            target: 'requestResetPassword'
                        }
                    }
                },
                requestResetPassword: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.requestResetPasswordApiUrlTemplate(),
                            method: 'POST',
                            data: (_, {email}) => ({
                                email
                            })
                        },
                        onDone: {
                            target: 'requestResetPasswordSuccess'
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'idle'
                        }
                    }
                },
                requestResetPasswordSuccess: {
                    on: {
                        BACK: {
                            target: '#unauthorized'
                        }
                    }
                }
            },
            on: {
                BACK: {
                    target: 'unauthorized'
                }
            }
        },
        changePassword: {
            entry: assign({
                error: {
                    data: {}
                }
            }),
            initial: 'edit',
            states: {
                edit: {
                    on: {
                        SUBMIT: {
                            target: 'changePassword'
                        }
                    }
                },
                changePassword: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.changePasswordApiUrlTemplate(),
                            method: 'POST',
                            data: (_, {password, passwordRepeat, passwordResetToken}) => ({
                                password,
                                passwordRepeat,
                                passwordResetToken
                            })
                        },
                        onDone: {
                            target: 'changePasswordSuccess'
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'edit'
                        }
                    }
                },
                changePasswordSuccess: {
                    on: {
                        BACK: {
                            target: '#unauthorized'
                        }
                    }
                },
                changePasswordError: {
                    on: {
                        BACK: {
                            target: '#unauthorized'
                        }
                    }
                }
            }
        },
        loading: {
            entry: assign({
                error: {
                    data: {}
                }
            }),
            invoke: {
                src: 'loginUser',
                data: {
                    ...context,
                    url: api.userLoginApiUrlTemplate(),
                    method: 'POST',
                    data: (_, {username, password}) => ({
                        username,
                        password
                    })
                },
                onDone: {
                    target: 'authorized',
                    actions: [
                        assign({
                            user: (_, {data}) => data.response,
                            error: {
                                data: {}
                            }
                        })
                    ]
                },
                onError: {
                    target: 'unauthorized',
                    actions: assign({
                        error: (_, event) => event
                    })
                }
            }
        },
        authorized: {
            invoke: {
                id: 'kttgApplication',
                src: 'kttgApplication',
                data: {
                    user: ({user}) => user,
                    registrationData: ({user}) => user.schoolApplication || {},
                    texts: [],
                    error: {
                        data: {}
                    }
                }
            },
            on: {
                LOGOUT: {
                    target: 'logout'
                }
            }
        },
        logout: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: '/logout',
                    method: 'POST'
                },
                onDone: {
                    target: 'unauthorized',
                    actions: [
                        assign({
                            message: () => 'logged out successfully',
                            error: {
                                data: {}
                            },
                            user: () => null
                        })
                    ]
                },
                onError: {
                    target: 'unauthorized'
                }
            }
        }
    }
});

export default authMachine;
