'use strict';

import {Component}    from 'react';
import * as PropTypes from 'prop-types';

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        console.error(error);
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return 'Something went wrong';
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any
};

export default ErrorBoundary;
