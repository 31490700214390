'use strict';

const {createMachine, assign} = require('xstate');
const {context} = require('./lib/fetchMachineContext');
const {api} = require('./lib/conf');

const authMachine = createMachine({
    id: 'registration',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        error: {
            data: {}
        },
        salutations: [],
        texts: [],
        registrationData: {
            salutation: '',
            firstName: '',
            lastName: '',
            socialSecurityNumber: '',
            email: '',
            password: '',
            passwordRepeat: ''
        }
    },
    states: {
        init: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.salutationsApiUrlTemplate()
                },
                onDone: {
                    target: 'getTexts',
                    actions: assign({
                        salutations: (_, {data}) => data.response
                    })
                },
                onError: {
                    target: 'registration',
                    actions: assign({
                        error: (_, event) => event
                    })
                }
            }
        },
        getTexts: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.formTextsSingleApiUrlTemplate('register')
                },
                onDone: {
                    target: 'registration',
                    actions: assign({
                        texts: (_, {data}) => data.response
                    })
                },
                onError: {
                }
            }
        },
        registration: {
            on: {
                SIGNUP: {
                    target: 'loading',
                    actions: assign({
                        registrationData: (_, event) => ({
                            salutation: event.salutation || '',
                            firstName: event.firstName || '',
                            lastName: event.lastName || '',
                            socialSecurityNumber: event.socialSecurityNumber || '',
                            email: event.email || '',
                            password: event.password || '',
                            passwordRepeat: event.passwordRepeat || ''
                        })
                    })
                }
            }
        },
        loading: {
            entry: assign({
                error: {
                    data: {}
                }
            }),
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.userSignUpApiUrlTemplate(),
                    method: 'POST',
                    data: (_, event) => event
                },
                onDone: {
                    target: 'verifyEmail',
                    actions: [
                        assign({
                            error: {
                                data: {}
                            }
                        })
                    ]
                },
                onError: {
                    target: 'registration',
                    actions: assign({
                        error: (_, event) => event
                    })
                }
            }
        },
        verifyEmail: {
            initial: 'afterRegistration',
            states: {
                afterRegistration: {}
            },
            on: {
                DONE: {
                    target: '#done'
                }
            }
        },
        done: {
            id: 'done',
            type: 'final'
        }
    }
});

export default authMachine;
