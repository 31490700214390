'use strict';

import isObject from 'lodash.isobject';
import FormData from 'form-data';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const {createMachine, assign} = require('xstate');

const studentMachine = createMachine({
    id: 'studentForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        languages: [],
        countries: [],
        salutations: [],
        user: {},
        userCurrentData: {},
        error: {
            data: {}
        }
    },
    states: {
        init: {
            entry: assign({
                userCurrentData: ({userCurrentData}) => ({
                    ...userCurrentData,
                    salutation: Array.isArray(userCurrentData.salutation) ? '' : userCurrentData.salutation.id,
                    firstName: userCurrentData.firstName || '',
                    lastName: userCurrentData.lastName || '',
                    socialSecurityNumber: userCurrentData.socialSecurityNumber || '',
                    email: userCurrentData.email || '',
                    birthday: userCurrentData.birthday || '',
                    country: Array.isArray(userCurrentData.country) ? '' : userCurrentData.country.id,
                    hometown: userCurrentData.hometown || '',
                    language: Array.isArray(userCurrentData.language) ? '' : userCurrentData.language.id,
                    mobile: userCurrentData.mobile || '',
                    useStudentData: userCurrentData.useStudentData || false,
                    hasHealthProblems: userCurrentData.hasHealthProblems || false,
                    healthProblems: userCurrentData.healthProblems || '',
                    healthProblemsFile: userCurrentData.healthProblemsFile || []
                })
            }),
            always: {
                target: '#edit'
            }
        },
        edit: {
            id: 'edit',
            on: {
                SUBMIT: {
                    target: 'save',
                    actions: assign({
                        userCurrentData: ({userCurrentData}, event) => ({
                            ...userCurrentData,
                            salutation: event.salutation || '',
                            firstName: event.firstName || '',
                            lastName: event.lastName || '',
                            socialSecurityNumber: event.socialSecurityNumber || '',
                            email: event.email || '',
                            birthday: event.birthday || '',
                            country: event.country || '',
                            hometown: event.hometown || '',
                            language: event.language || '',
                            mobile: event.mobile || '',
                            useStudentData: event.useStudentData || false,
                            hasHealthProblems: event.hasHealthProblems || false,
                            healthProblems: event.healthProblems || '',
                            newFileToUpload: event.healthProblemsFile || null
                        })
                    })
                },
                DELETEFILE: {
                    target: 'deleteFile'
                }
            }
        },
        deleteFile: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.studentSingleDocumentApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'DELETE'
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => data.response
                    }),
                    target: '#edit'
                },
                onError: {
                    target: '#edit'
                }
            }
        },
        save: {
            id: 'save',
            initial: 'checkFileToSave',
            states: {
                checkFileToSave: {
                    invoke: {
                        src: ({userCurrentData}) => {
                            if (userCurrentData.newFileToUpload) {
                                return Promise.resolve();
                            }

                            return Promise.reject();
                        },
                        onDone: {
                            target: 'saveFile'
                        },
                        onError: {
                            target: 'saveData'
                        }
                    }
                },
                saveFile: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({userCurrentData}) => {
                                return api.studentSingleDocumentApiUrlTemplate(userCurrentData.id)
                            },
                            method: 'POST',
                            headers: {},
                            data: ({userCurrentData}) => {

                                const formData = new FormData();
                                formData.append('healthProblemsFile', userCurrentData.newFileToUpload);

                                return formData;
                            }
                        },
                        onDone: {
                            actions: assign({
                                userCurrentData: (_, {data}) => data.response
                            }),
                            target: 'saveData'
                        },
                        onError: {
                            target: '#edit'
                        }
                    }
                },
                saveData: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({userCurrentData}) => {
                                return api.studentSingleApiUrlTemplate(userCurrentData.id)
                            },
                            method: 'PUT',
                            data: ({userCurrentData}) => {
                                // for some reason we got twice here :(
                                // the request is made only once though
                                if (userCurrentData.salutation === '') {
                                    userCurrentData.salutation = [];
                                }

                                if (
                                    !Array.isArray(userCurrentData.salutation) &&
                                    !isObject(userCurrentData.salutation) &&
                                    userCurrentData.salutation !== ''
                                ) {
                                    userCurrentData.salutation = {
                                        id: userCurrentData.salutation
                                    };
                                }

                                if (userCurrentData.language === '') {
                                    userCurrentData.language = [];
                                }

                                if (
                                    !Array.isArray(userCurrentData.language) &&
                                    !isObject(userCurrentData.language) &&
                                    userCurrentData.language !== ''
                                ) {
                                    userCurrentData.language = {
                                        id: userCurrentData.language
                                    };
                                }

                                if (userCurrentData.country === '') {
                                    userCurrentData.country = [];
                                }

                                if (
                                    !Array.isArray(userCurrentData.country) &&
                                    !isObject(userCurrentData.country) &&
                                    userCurrentData.country !== ''
                                ) {
                                    userCurrentData.country = {
                                        id: userCurrentData.country
                                    };
                                }

                                delete userCurrentData.healthProblemsFile;
                                delete userCurrentData.newFileToUpload;

                                return {
                                    ...userCurrentData,
                                    editing: 0,
                                    complete: 1
                                };
                            }
                        },
                        onDone: {
                            actions: assign({
                                userCurrentData: (_, {data}) => data
                            }),
                            target: '#done'
                        },
                        onError: {
                            target: '#edit'
                        }
                    }
                }
            }
        },
        done: {
            id: 'done',
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default studentMachine;
