'use strict';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const {createMachine, assign} = require('xstate');

const classTeacherMachine = createMachine({
    id: 'resumeFormMachine',
    predictableActionArguments: true,
    initial: 'edit',
    context: {
        userName: '',
        userCurrentData: {}
    },
    states: {
        edit: {
            on: {
                SUBMIT: {
                    target: 'saveData',
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.schoolApplicationSingleApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'PUT',
                    data: {
                        resumeForm: 0
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => data
                    }),
                    target: 'done'
                },
                onError: {
                    target: 'edit'
                }
            }
        },
        done: {
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default classTeacherMachine;
