'use strict';

import React                          from 'react';
import PropTypes                      from 'prop-types';
import {useActor}                     from '@xstate/react';
import {useFormik}                    from 'formik';
import {
    string,
    object
} from 'yup';

import { FormGuide }    from '../formGuide';
import {useTranslation} from 'react-i18next';

export const ClassTeacherForm = ({service}) => {
    const [state, send] = useActor(service);
    const {context} = state;
    const {t} = useTranslation();

    const {error: errorObject = {}} = context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const {
        userCurrentData,
        salutations = [
            {
                id: 1,
                name: 'Herr'
            },
            {
                id: 2,
                name: 'Frau'
            }
        ],
        texts: formText,
        userName
    } = context;

    const payload = {
        salutation: userCurrentData.salutation || '',
        firstName: userCurrentData.firstName || '',
        lastName: userCurrentData.lastName || '',
        street: userCurrentData.street || '',
        streetNumber: userCurrentData.streetNumber || '',
        zip: userCurrentData.zip || '',
        city: userCurrentData.city || '',
        mobile: userCurrentData.mobile || '',
        phone: userCurrentData.phone || '',
        email: userCurrentData.email || ''
    };

    const validationSchema = object({
        firstName: string(),
        lastName: string(),
        street: string(),
        streetNumber: string(),
        zip: string(),
        city: string(),
        mobile: string(),
        phone: string(),
        email: string().email(t('wrongEmailFormat')),
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return send(
                {
                    type: 'SUBMIT',
                    ...values
                }
            );
        }
    });

    const getSalutationOptions = () => {
        return salutations.map(({id, name}) => {
            return <option key={id} value={id}>{name}</option>;
        });
    };

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>{t('myClassTeacher')}: {userName}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <div>

                                    {errorCodes &&
                                        <div className={'errors'}>
                                            {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                        </div>
                                    }

                                    <FormGuide html={formText[0] ? formText[0] : ''}>
                                        <label htmlFor={'salutation'}>Anrede</label>
                                        {formik.errors.salutation && formik.touched.salutation ?
                                            <div className={'errors'}>{formik.errors.salutation}</div>
                                            : null}
                                        <span className={'select-wrapper'}>
                                            <select
                                                name={'salutation'}
                                                value={formik.values.salutation}
                                                className={
                                                    formik.errors.salutation &&
                                                    formik.touched.salutation ?
                                                        'error' :
                                                        ''
                                                }
                                                onChange={formik.handleChange}
                                            >
                                                <option value={''} disabled={true}>{t('optionsDefaultValue')}</option>
                                                {getSalutationOptions()}
                                            </select>
                                        </span>
                                    </FormGuide>
                                    <FormGuide html={formText[1] ? formText[1] : ''}>
                                        <label htmlFor={'firstName'}>{t('firstName')}</label>
                                        {formik.errors.firstName && formik.touched.firstName ?
                                            <div className={'errors'}>{formik.errors.firstName}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'firstName'}
                                            value={formik.values.firstName}
                                            className={
                                                formik.errors.firstName &&
                                                formik.touched.firstName ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'Max'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[2] ? formText[2] : ''}>
                                        <label htmlFor={'lastName'}>{t('lastName')}</label>
                                        {formik.errors.lastName && formik.touched.lastName ?
                                            <div className={'errors'}>{formik.errors.lastName}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'lastName'}
                                            value={formik.values.lastName}
                                            className={
                                                formik.errors.lastName &&
                                                formik.touched.lastName ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'Mustermann'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[3] ? formText[3] : ''}>
                                        <label htmlFor={'street'}>{t('street')}</label>
                                        {formik.errors.street && formik.touched.street ?
                                            <div className={'errors'}>{formik.errors.street}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'street'}
                                            value={formik.values.street}
                                            className={
                                                formik.errors.street &&
                                                formik.touched.street ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'Musterstrasse'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[4] ? formText[4] : ''}>
                                        <label htmlFor={'streetNumber'}>{t('streetNumber')}</label>
                                        {formik.errors.streetNumber && formik.touched.streetNumber ?
                                            <div className={'errors'}>{formik.errors.streetNumber}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'streetNumber'}
                                            value={formik.values.streetNumber}
                                            className={
                                                formik.errors.streetNumber &&
                                                formik.touched.streetNumber ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'66'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[5] ? formText[5] : ''}>
                                        <label htmlFor={'zip'}>{t('zip')}</label>
                                        {formik.errors.zip && formik.touched.zip ?
                                            <div className={'errors'}>{formik.errors.zip}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'zip'}
                                            value={formik.values.zip}
                                            className={
                                                formik.errors.zip &&
                                                formik.touched.zip ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'8001'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[6] ? formText[6] : ''}>
                                        <label htmlFor={'city'}>{t('city')}</label>
                                        {formik.errors.city && formik.touched.city ?
                                            <div className={'errors'}>{formik.errors.city}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'city'}
                                            value={formik.values.city}
                                            className={
                                                formik.errors.city &&
                                                formik.touched.city ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'Frauenfeld'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[7] ? formText[7] : ''}>
                                        <label htmlFor={'mobile'}>{t('mobile')}</label>
                                        {formik.errors.mobile && formik.touched.mobile ?
                                            <div className={'errors'}>{formik.errors.mobile}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'mobile'}
                                            value={formik.values.mobile}
                                            className={
                                                formik.errors.mobile &&
                                                formik.touched.mobile ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'+41 132463'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[8] ? formText[8] : ''}>
                                        <label htmlFor={'phone'}>{t('phone')}</label>
                                        {formik.errors.phone && formik.touched.phone ?
                                            <div className={'errors'}>{formik.errors.phone}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'phone'}
                                            value={formik.values.phone}
                                            className={
                                                formik.errors.phone &&
                                                formik.touched.phone ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'Telefonnummer (Festnetz)'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[9] ? formText[9] : ''}>
                                        <label htmlFor={'email'}>{t('email')}</label>
                                        {formik.errors.email && formik.touched.email ?
                                            <div className={'errors'}>{formik.errors.email}</div>
                                            : null}
                                        <input
                                            type={'email'}
                                            name={'email'}
                                            value={formik.values.email}
                                            className={
                                                formik.errors.email &&
                                                formik.touched.email ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'max.mustermann@example.ch'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                </div>
                                <button
                                    type={'submit'}
                                    className={'submit button primary margin-top-2'}
                                >
                                    {t('continue')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

ClassTeacherForm.propTypes = {
    service: PropTypes.any
}
