'use strict';

import React , {useState}      from 'react';
import parse                   from 'html-react-parser'
import PropTypes               from 'prop-types'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { GrClose }             from 'react-icons/gr'
import isObject                from 'lodash.isobject';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const FormGuide = ( {html, hideMobileInfo, children} ) => {

    const [modalOpen, setModalOpen] = useState(false);

    const parsed = isObject(html) ? html : parse(html);

    if (!html.length > 0) {
        hideMobileInfo = true;
    }

    return (
        <>
            <div className='grid-x form-guide'>
                <div className={ hideMobileInfo ? 'cell auto' : 'cell auto field' }>
                    {children}
                </div>

                <div className={'cell auto guide'}>
                    {parsed}
                </div>

                {
                    html.length > 0 && !hideMobileInfo &&
                    <div className='info-toggle' onClick={() => setModalOpen(true)}>
                        <AiOutlineInfoCircle/>
                    </div>
                }

                {
                    modalOpen &&
                    <div className={'guide-modal'}>
                        <GrClose className={'close-modal'} onClick={() => setModalOpen(false)}/>
                        <div className={'modal-content'}>
                            {parsed}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

FormGuide.propTypes = {
    html: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    children: PropTypes.any,
    hideMobileInfo: PropTypes.bool
};