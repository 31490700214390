'use strict';

import React                from 'react';
import PropTypes            from 'prop-types';
import {useActor}           from '@xstate/react';
import {DocumentUpload}     from '../documentUpload';
import { BiTrashAlt }       from 'react-icons/bi';
import { AiOutlineFile }    from 'react-icons/ai';

export const DocumentUploadForm = ({service}) => {
    const [state, send] = useActor(service);
    const {
        userCurrentData,
        texts: formText,
        userName,
        error: errorObject = {}
    } = state.context;

    const {data = {}} = errorObject;
    const {details: errorDetails = {}} = data;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const {
        documents
    } = userCurrentData;

    const getDocumentsOutput = () => {
        return documents.map(({id, file_name: fileName}) => {
            return (
                <div key={id} className="preview-actions cell auto">
                    <AiOutlineFile/>
                    <span
                        className="title"
                        title={fileName}
                    >
                        {fileName}
                    </span>
                    <button
                        type={'button'}
                        className="link delete"
                        onClick={() => {
                            send({
                                type: 'DELETEFILE',
                                fileId: id
                            });
                        }}
                    >
                        <BiTrashAlt/>
                    </button>
                </div>
            );
        })
    };

    const getNavigation = () => {
        return (
            <button
                type={'submit'}
                className={'submit button primary margin-top-2'}
            >
                Weiter
            </button>
        )
    };

    const submit = values => {
        return send(
            {
                type: 'SUBMIT',
                ...values
            }
        );
    };

    const addFiles = files => {
        return send({
            type: 'ADDFILES',
            files
        });
    };

    return (
        <DocumentUpload
            send={send}
            getDocumentsOutput={getDocumentsOutput}
            getNavigation={getNavigation}
            submit={submit}
            addFiles={addFiles}
            formText={formText}
            userName={userName}
            userCurrentData={userCurrentData}
            errorCodes={errorCodes}
        />
    );
};

DocumentUploadForm.propTypes = {
    service: PropTypes.any
};
