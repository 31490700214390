'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';
import Loader        from '../loader';
import {StudentForm} from '../studentForm';

export const StudentContainer = ({applicationFormService}) => {
    const studentMachine = applicationFormService.children.get('studentForm');

    const isLoading = useSelector(
        studentMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <StudentForm
                    service={studentMachine}
                />
            }
        </>
    )
};

StudentContainer.propTypes = {
    applicationFormService: PropTypes.any
}
