'use strict';

import React                               from 'react';
import {useActor, useSelector}             from '@xstate/react';
import PropTypes                           from 'prop-types';
import {object, string}                    from 'yup';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useNavigate}                 from 'react-router-dom';

import {FormGuide}      from '../formGuide';
import Loader           from '../loader';
import {useTranslation} from 'react-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ResetPassword = ({authService}) => {

    const [state, send] = useActor(authService);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {error: errorObject = {}} = state.context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const isLoading = useSelector(authService, state => (
        state.matches('resetPassword.requestResetPassword')
    ));
    const inResetPasswordStep = useSelector(authService, state => (
        state.matches('resetPassword.idle')
    ));
    const inResetPasswordSuccessStep = useSelector(authService, state => (
        state.matches('resetPassword.requestResetPasswordSuccess')
    ));

    const payload = {
        email: ''
    };

    const validationSchema = object({
        email: string()
            .email(t('wrongEmailFormat'))
            .required(t('emailRequiredMessage'))
    });

    const requestResetPasswordEmail = payload => {
        return send({
            type: 'REQUESTRESETPASSWORD',
            ...payload
        });
    };

    return (
        <div>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <div className={'grid-container grid-y'}>
                    <div
                        className={[
                            'small-auto',
                            'large-padding-top_bottom',
                            'medium-padding-top_bottom',
                            'small-padding-top_bottom',
                            'content-container'
                        ].join(' ')}>

                        <div className={'margin-bottom-3'}>
                            <h1>
                                <strong>{t('resetPasswordTitle')}</strong>
                            </h1>
                        </div>
                        {inResetPasswordStep &&

                            <Formik
                                initialValues={payload}
                                onSubmit={requestResetPasswordEmail}
                                validationSchema={validationSchema}
                            >
                                {({errors, touched}) => {
                                    return (
                                        <Form>
                                            <div>

                                                {errorCodes &&
                                                    <div className={'errors'}>
                                                        {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                                    </div>
                                                }

                                                <FormGuide html={''}>
                                                    <label htmlFor={'email'}>{t('email')}</label>
                                                    <ErrorMessage component="div" name="email" className="errors"/>
                                                    <Field
                                                        name={'email'}
                                                        type={'email'}
                                                        className={errors.email && touched.email ? 'error' : ''}
                                                    />
                                                </FormGuide>
                                                <button
                                                    type={'submit'}
                                                    className={'submit button primary margin-top-2'}
                                                >
                                                    {t('resetPasswordTitle')}
                                                </button>
                                                <button
                                                    type={'button'}
                                                    className={'clear button margin-top-2'}
                                                    onClick={() => {
                                                        send('BACK');
                                                        navigate('/');
                                                    }}
                                                >
                                                    {t('cancel')}
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        }
                        {inResetPasswordSuccessStep &&
                            <>
                                <p>{t('resetPasswordSuccessEmailMessage')}</p>
                                <button
                                    className={'primary button margin-top-2'}
                                    onClick={() => {
                                        send('BACK');
                                        navigate('/');
                                    }}
                                >
                                    {t('backToLogin')}
                                </button>
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

ResetPassword.propTypes = {
    authService: PropTypes.shape()
};
