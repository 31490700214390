'use strict';

export const context = {
    data: {},
    url: null,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
    response: null,
    errorMessage: null,
    retriesCount: 0
};
