'use strict';

const isEmpty = require('lodash.isempty');

const calculateStep = (data, callback) => {

    const {
        complete,
        classTeacher,
        currentSchool,
        legalRepresentative,
        schoolGoal,
        student,
        documentsUpload,
        resumeForm
    } = data;

    if (complete) {
        callback('REGISTRATIONCOMPLETE');
        return;
    }

    if (resumeForm) {
        callback('RESUMEFORMONLOGIN');
        return;
    }

    if (
        !complete &&
        student.complete &&
        classTeacher.complete &&
        currentSchool.complete &&
        legalRepresentative.complete &&
        schoolGoal.complete &&
        documentsUpload.complete
    ) {
        callback('DATAOVERVIEW');
        return;
    }

    if (classTeacher && !isEmpty(classTeacher)) {
        const {editing} = classTeacher;
        if (editing) {
            callback('TEACHERDATA');
            return;
        }
    }

    if (documentsUpload && !isEmpty(documentsUpload)) {
        const {editing} = documentsUpload;
        if (editing) {
            callback('DOCUMENTSUPLOAD');
            return;
        }
    }

    if (currentSchool && !isEmpty(currentSchool)) {
        const {editing} = currentSchool;
        if (editing) {
            callback('CURRENTSCHOOLDATA');
            return;
        }
    }

    if (schoolGoal && !isEmpty(schoolGoal)) {
        const {editing} = schoolGoal;
        if (editing) {
            callback('SCHOOLGOALDATA');
            return;
        }
    }

    if (legalRepresentative && !isEmpty(legalRepresentative)) {
        const {editing} = legalRepresentative;
        if (editing) {
            callback('PARENTSDATA');
            return;
        }
    }

    callback('STUDENTDATA');
};

module.exports = calculateStep;
