'use strict';

import React                from 'react';
import PropTypes            from 'prop-types';
import {useFormik}          from 'formik';
import {
    object, mixed, boolean, string,
} from 'yup'
import isEmpty              from 'lodash.isempty';
import parse                from 'html-react-parser'

import {FormGuide}      from '../formGuide';
import {useTranslation} from 'react-i18next';
import {UploadField}    from '../uploadField';

export const DocumentUpload = (
    {
        getDocumentsOutput,
        getNavigation,
        submit,
        addFiles,
        formText,
        userName = '',
        userCurrentData = {},
        errorCodes
    }
) => {

    const [dragActive, setDragActive] = React.useState(false);
    const {t} = useTranslation();

    const fileSizeInMb = 5;
    const fileSize = fileSizeInMb * 1024 * 1024; // 10Mb
    const supportedFileTypes = [
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'image/png'
    ];

    const payload = {
        filesToUpload: undefined,
        hasHealthProblems: !!userCurrentData.hasHealthProblems,
        healthProblems: userCurrentData.healthProblems || '',
        hasFilesUploaded: userCurrentData.documents.length > 0
    };

    const validationSchema = object({
        filesToUpload: mixed()
            .test(
                'Dateigrösse',
                t('documents_upload_limit_file_size', {fileSizeInMb: 5}),
                value => {
                    if (value && value.length) {
                        for (let i = 0; i < value.length; i++) {
                            if (value[i].size > fileSize) {
                                return false;
                            }
                        }
                    }

                    return true;
                }
            )
            .test(
                'Dateiformat',
                'Nicht unterstütztes Dateiformat. Unterstützte Formate: JPG, JPEG, PDF, BMP, PNG',
                value => {
                    if (value && value.length) {
                        for (let i = 0; i < value.length; i++) {
                            if (!supportedFileTypes.includes(value[i].type)) {
                                return false;
                            }
                        }
                    }

                    return true;
                }
            ),
        hasHealthProblems: boolean(),
        healthProblems: string(),
        hasFilesUploaded: boolean()
            .oneOf(
                [true],
                t('fileUploadRequiredMessage')
            )
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return submit(values);
        }
    });

    const addFilesCallback = (
        currentTargetFiles,
        targetFiles,
        {
            setFieldValue,
            validateForm,
            setErrors
        }) => {
        return setFieldValue(
            'filesToUpload',
            currentTargetFiles,
            false
        )
            .then(() => {
                return validateForm();
            })
            .then(response => {

                delete response.hasFilesUploaded;

                if (isEmpty(response)) {
                    setErrors({});
                    setFieldValue(
                        'hasFilesUploaded',
                        true,
                        false
                    );
                    return addFiles(targetFiles);
                }
            })
    };

    // handle drag events
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            return addFilesCallback(e.dataTransfer.files, e.dataTransfer.files, formik);
        }
    };


    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Dokumente beifügen{userName !== '' ? `: ${userName}` : ''}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit} onDragEnter={handleDrag}>
                            <div>
                                <div>
                                    <FormGuide html={formText[0] ? formText[0] : ''}>
                                        <UploadField
                                            formik={formik}
                                            documents={userCurrentData.documents || []}
                                            addFilesCallback={addFilesCallback}
                                            handleDrag={handleDrag}
                                            handleDrop={handleDrop}
                                            dragActive={dragActive}
                                            errorCodes={errorCodes}
                                        />

                                    </FormGuide>
                                    <FormGuide html={formText[1] ? formText[1] : ''}>
                                        {getDocumentsOutput()}
                                    </FormGuide>
                                    <FormGuide html={formText[2] ? formText[2] : ''}>
                                        <div>
                                            <div className={'checkbox-with-label'}>
                                                <input
                                                    type={'checkbox'}
                                                    name={'hasHealthProblems'}
                                                    id={'hasHealthProblems'}
                                                    checked={formik.values.hasHealthProblems}
                                                    onChange={formik.handleChange}
                                                />
                                                <label htmlFor={'hasHealthProblems'}>
                                                    Gewichtige gesundheitliche Probleme (Optional)
                                                </label>
                                            </div>
                                            {
                                                formik.values.hasHealthProblems && formText[2] &&
                                                <div>
                                                    {parse(formText[2])}
                                                </div>
                                            }
                                            {
                                                formik.errors.hasHealthProblems ?
                                                    <div className={'errors'}>
                                                        {formik.errors.hasHealthProblems}
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        {formik.values.hasHealthProblems &&
                                            <>
                                                <label htmlFor={'healthProblems'}>Bitte geben Sie den Grund an</label>
                                                <input
                                                    type={'text'}
                                                    name={'healthProblems'}
                                                    id={'healthProblems'}
                                                    value={formik.values.healthProblems}
                                                    onChange={formik.handleChange}
                                                />
                                            </>
                                        }
                                    </FormGuide>
                                </div>
                                {getNavigation()}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

DocumentUpload.propTypes = {
    documents: PropTypes.array,
    formText: PropTypes.array,
    submit: PropTypes.func,
    addFiles: PropTypes.func,
    getDocumentsOutput: PropTypes.func,
    getNavigation: PropTypes.func,
    userName: PropTypes.string,
    userCurrentData: PropTypes.object,
    errorCodes: PropTypes.array
};
