'use strict';

import React       from 'react';
import PropTypes   from 'prop-types';
import {useActor}  from '@xstate/react';
import {useFormik} from 'formik';
import {
    string,
    object, boolean
}                  from 'yup';

import {InputAdvancedSelect} from '../input-advanced-select';
import {FormGuide}           from '../formGuide';
import DatePicker            from '../datePicker';
import {AHV13}               from 'ahv13-validator';

export const StudentForm = ({service}) => {
    const [state, send] = useActor(service);

    const {
        user,
        userCurrentData,
        salutations = [
            {
                id: 1,
                name: 'Herr'
            },
            {
                id: 2,
                name: 'Frau'
            }
        ],
        languages,
        countries,
        texts: formText
    } = state.context;

    let birthday;
    if (userCurrentData && userCurrentData.birthday && userCurrentData.birthday !== '') {
        try {
            birthday = new Date(userCurrentData.birthday);
        } catch (e) {
            birthday = '';
        }
    }

    const payload = {
        salutation: userCurrentData.salutation || '',
        firstName: userCurrentData.firstName || '',
        lastName: userCurrentData.lastName || '',
        socialSecurityNumber: userCurrentData.socialSecurityNumber || '',
        email: userCurrentData.email || '',
        birthday: birthday || '',
        country: userCurrentData.country || '',
        hometown: userCurrentData.hometown || '',
        language: userCurrentData.language || '',
        mobile: userCurrentData.mobile || '',
        useStudentData: !!userCurrentData.useStudentData,
        userSalutation: user.salutation || '',
        userFirstName: user.firstName || '',
        userLastName: user.lastName || '',
        userSocialSecurityNumber: user.socialSecurityNumber || '',
        userEmail: user.email || ''
    };

    const validationSchema = object({
        useStudentData: boolean(),
        salutation: string()
            .oneOf([
                ...salutations.map(({id}) => id),
                null
            ], 'Invalide Anrede')
            .when('useStudentData', {
                is: true,
                then: string().required('Anrede ist ein Pflichtfeld')
            }),
        firstName: string()
            .when('useStudentData', {
                is: true,
                then: string().required('Vorname ist ein Pflichtfeld')
            }),
        lastName: string()
            .when('useStudentData', {
                is: true,
                then: string().required('Nachname ist ein Pflichtfeld')
            }),
        email: string()
            .when('useStudentData', {
                is: true,
                then: string().email('Ungültige E-Mail').required('E-Mail ist ein Pflichtfeld')
            }),
        birthday: string().required('Geburtsdatum ist ein Pflichtfeld'),
        country: string()
            .oneOf([
                ...countries.map(({id}) => id),
                null
            ], 'Invalide Nationalität')
            .required('Nationalität ist ein Pflichtfeld'),
        language: string()
            .oneOf([
                ...languages.map(({id}) => id),
                null
            ], 'Invalide Muttersprache')
            .required('Muttersprache ist ein Pflichtfeld'),
        mobile: string()
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        onSubmit: values => {
            return send(
                {
                    type: 'SUBMIT',
                    ...values
                }
            );
        },
        validate: ({useStudentData, socialSecurityNumber}) => {
            const errors = {};

            if (useStudentData) {
                if (!socialSecurityNumber) {
                    errors.socialSecurityNumber = 'Sozialversicherungsnr. ist ein Pflichtfeld';

                    return errors;
                }

                const ssNumberValidator = new AHV13();
                if (!ssNumberValidator.isValid(socialSecurityNumber)) {
                    errors.socialSecurityNumber = 'Ungültige Sozialversicherungsnr.';
                }
            }

            return errors;
        }
    });

    const getSalutationOptions = () => {
        return salutations.map(({id, name}) => {
            return <option key={id} value={id}>{name}</option>;
        });
    };

    const convertCountryArrayToObject = countries => {
        let index = 0;
        return countries.reduce((prev, {id, name}) => {
            prev[index] = {
                id,
                name
            };

            index++;
            return prev;
        }, {});
    };

    const convertLanguageArrayToObject = languages => {
        let index = 0;
        return languages.reduce((prev, {id, name}) => {
            prev[index] = {
                id,
                name
            };
            index++;
            return prev;
        }, {});
    };

    const getUseStudentDataCheckbox = () => {
        return (
            <>
                {!formik.values.useStudentData &&
                    <>
                        {formText[0] !== '' &&
                            <div dangerouslySetInnerHTML={{__html: formText[0]}}/>
                        }
                    </>
                }
                {formik.values.useStudentData &&
                    <>
                        {formText[1] !== '' &&
                            <div dangerouslySetInnerHTML={{__html: formText[1]}}/>
                        }
                    </>
                }
                <input
                    type={'checkbox'}
                    name={'useStudentData'}
                    id={'useStudentData'}
                    checked={formik.values.useStudentData}
                    onChange={formik.handleChange}
                />
                <label htmlFor={'useStudentData'}>
                    Daten bearbeiten
                </label>
            </>
        );
    }

    const getName = () => {
        if (formik.values.useStudentData) {
            return `${formik.values.firstName} ${formik.values.lastName}`
        }

        return `${user.firstName} ${user.lastName}`
    }

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Kandidatin / Kandidat: {getName()}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <div>
                                    {!formik.values.useStudentData &&
                                        <>
                                            <FormGuide html={getUseStudentDataCheckbox()}>
                                                <label aria-disabled htmlFor={'userSalutation'}>Anrede *</label>
                                                <span className={'select-wrapper'}>
                                                    <select
                                                        name={'userSalutation'}
                                                        value={formik.values.userSalutation.id}
                                                        disabled={true}
                                                    >
                                                        <option
                                                            key={formik.values.userSalutation.id}
                                                            value={formik.values.userSalutation.id}
                                                        >
                                                            {formik.values.userSalutation.name}
                                                        </option>
                                                    </select>
                                                </span>
                                            </FormGuide>

                                            <FormGuide html={formText[2] ? formText[2] : ''}>
                                                <label aria-disabled htmlFor={'userFirstName'}>Vorname *</label>
                                                <input
                                                    type={'text'}
                                                    name={'userFirstName'}
                                                    value={formik.values.userFirstName}
                                                    onChange={() => {
                                                    }}
                                                    disabled={true}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[3] ? formText[3] : ''}>
                                                <label aria-disabled htmlFor={'userLastName'}>Nachname *</label>
                                                <input
                                                    type={'text'}
                                                    name={'userLastName'}
                                                    value={formik.values.userLastName}
                                                    onChange={() => {
                                                    }}
                                                    disabled={true}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[4] ? formText[4] : ''}>
                                                <label aria-disabled htmlFor={'userSocialSecurityNumber'}>
                                                    Sozialversicherungsnr.
                                                </label>
                                                <input
                                                    type={'text'}
                                                    name={'userSocialSecurityNumber'}
                                                    value={formik.values.userSocialSecurityNumber}
                                                    onChange={() => {
                                                    }}
                                                    disabled={true}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[5] ? formText[5] : ''}>
                                                <label aria-disabled htmlFor={'userSalutation'}>E-Mail-Adresse *</label>
                                                <input
                                                    type={'email'}
                                                    name={'userEmail'}
                                                    value={formik.values.userEmail}
                                                    onChange={() => {
                                                    }}
                                                    disabled={true}
                                                />
                                            </FormGuide>
                                        </>
                                    }
                                    {formik.values.useStudentData &&
                                        <>
                                            <FormGuide html={getUseStudentDataCheckbox()}>
                                                <label htmlFor={'salutation'}>Anrede *</label>
                                                {formik.errors.salutation && formik.touched.salutation ?
                                                    <div className={'errors'}>{formik.errors.salutation}</div>
                                                    : null}
                                                <span className={'select-wrapper'}>
                                                    <select
                                                        name={'salutation'}
                                                        value={formik.values.salutation}
                                                        className={
                                                            formik.errors.salutation &&
                                                            formik.touched.salutation ?
                                                                'error' :
                                                                ''
                                                        }
                                                        onChange={event => {
                                                            formik.handleChange(event);
                                                            formik.setFieldTouched('salutation', true, false);
                                                        }}
                                                    >
                                                        <option value={''} disabled={true}>- Bitte wählen -</option>
                                                        {getSalutationOptions()}
                                                    </select>
                                                </span>
                                            </FormGuide>

                                            <FormGuide html={formText[6] ? formText[6] : ''}>
                                                <label htmlFor={'firstName'}>Vorname *</label>
                                                {formik.errors.firstName && formik.touched.firstName ?
                                                    <div className={'errors'}>{formik.errors.firstName}</div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'firstName'}
                                                    value={formik.values.firstName}
                                                    className={
                                                        formik.errors.firstName &&
                                                        formik.touched.firstName ?
                                                            'error' :
                                                            ''
                                                    }
                                                    placeholder={'Max'}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[7] ? formText[7] : ''}>
                                                <label htmlFor={'lastName'}>Nachname *</label>
                                                {formik.errors.lastName && formik.touched.lastName ?
                                                    <div className={'errors'}>{formik.errors.lastName}</div>
                                                    : null}
                                                <input
                                                    type={'text'}
                                                    name={'lastName'}
                                                    value={formik.values.lastName}
                                                    className={
                                                        formik.errors.lastName &&
                                                        formik.touched.lastName ?
                                                            'error' :
                                                            ''
                                                    }
                                                    placeholder={'Mustermann'}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[8] ? formText[8] : ''}>
                                                <label htmlFor={'socialSecurityNumber'}>Sozialversicherungsnr. *</label>
                                                {
                                                    formik.errors.socialSecurityNumber &&
                                                    formik.touched.socialSecurityNumber ?
                                                        <div className={'errors'}>
                                                            {formik.errors.socialSecurityNumber}
                                                        </div> :
                                                        null
                                                }
                                                <input
                                                    type={'text'}
                                                    name={'socialSecurityNumber'}
                                                    value={formik.values.socialSecurityNumber}
                                                    className={
                                                        formik.errors.socialSecurityNumber &&
                                                        formik.touched.socialSecurityNumber ?
                                                            'error' :
                                                            ''
                                                    }
                                                    id={'socialSecurityNumber'}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                            <FormGuide html={formText[9] ? formText[9] : ''}>
                                                <label htmlFor={'email'}>E-Mail-Adresse *</label>
                                                {formik.errors.email && formik.touched.email ?
                                                    <div className={'errors'}>{formik.errors.email}</div>
                                                    : null}
                                                <input
                                                    type={'email'}
                                                    name={'email'}
                                                    value={formik.values.email}
                                                    className={
                                                        formik.errors.email &&
                                                        formik.touched.email ?
                                                            'error' :
                                                            ''
                                                    }
                                                    placeholder={'max.mustermann@thurgau.ch'}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGuide>
                                        </>
                                    }

                                    <FormGuide html={formText[10] ? formText[10] : ''}>
                                        <label htmlFor={'birthday'}>Geburtsdatum *</label>
                                        {formik.errors.birthday && formik.touched.birthday ?
                                            <div className={'errors'}>{formik.errors.birthday}</div>
                                            : null}
                                        <DatePicker
                                            name={birthday}
                                            value={formik.values.birthday}
                                            onChange={val => formik.setFieldValue('birthday', val)}
                                        />
                                    </FormGuide>
                                    <FormGuide html={formText[11] ? formText[11] : ''}>
                                        <label htmlFor={'country'}>Nationalität *</label>
                                        {formik.errors.country && formik.touched.country ?
                                            <div className={'errors'}>{formik.errors.country}</div>
                                            : null}
                                        <span className={'select-wrapper'}>
                                            <InputAdvancedSelect
                                                options={[]}
                                                suggestions={convertCountryArrayToObject(countries) || {}}
                                                pleaseChoose={'- Bitte wählen -'}
                                                onChange={({item}) => {
                                                    formik.setFieldValue('country', item.id);
                                                }}
                                                value={formik.values.country}
                                                errorClass={
                                                    formik.errors.language &&
                                                    formik.touched.language ?
                                                        'error' :
                                                        ''
                                                }
                                                initialValue={''}
                                                name={'country'}
                                                showSuggestionsOnFocus={true}
                                            />
                                        </span>
                                    </FormGuide>
                                    {parseInt(formik.values.country) === 1 &&
                                        <FormGuide html={formText[14] ? formText[14] : ''}>
                                            <label htmlFor={'hometown'}>Heimatort</label>
                                            {formik.errors.hometown && formik.touched.hometown ?
                                                <div className={'errors'}>{formik.errors.hometown}</div>
                                                : null}
                                            <input
                                                type={'text'}
                                                name={'hometown'}
                                                id={'hometown'}
                                                value={formik.values.hometown}
                                                className={
                                                    formik.errors.hometown &&
                                                    formik.touched.hometown ?
                                                        'error' :
                                                        ''
                                                }
                                                onChange={formik.handleChange}
                                            />
                                        </FormGuide>
                                    }
                                    <FormGuide html={formText[12] ? formText[12] : ''}>
                                        <label htmlFor={'language'}>Muttersprache *</label>
                                        {formik.errors.language && formik.touched.language ?
                                            <div className={'errors'}>{formik.errors.language}</div>
                                            : null}
                                        <span className={'select-wrapper'}>
                                            <InputAdvancedSelect
                                                options={[]}
                                                suggestions={convertLanguageArrayToObject(languages) || {}}
                                                pleaseChoose={'- Bitte wählen -'}
                                                onChange={({item}) => {
                                                    formik.setFieldValue('language', item.id);
                                                }}
                                                errorClass={
                                                    formik.errors.language &&
                                                    formik.touched.language ?
                                                        'error' :
                                                        ''
                                                }
                                                value={formik.values.language}
                                                initialValue={''}
                                                name={'language'}
                                                showSuggestionsOnFocus={true}
                                            />
                                        </span>
                                    </FormGuide>
                                    <FormGuide html={formText[13] ? formText[13] : ''}>
                                        <label htmlFor={'mobile'}>Telefonnummer (Mobil)</label>
                                        {formik.errors.mobile && formik.touched.mobile ?
                                            <div className={'errors'}>{formik.errors.mobile}</div>
                                            : null}
                                        <input
                                            type={'text'}
                                            name={'mobile'}
                                            value={formik.values.mobile}
                                            className={
                                                formik.errors.mobile &&
                                                formik.touched.mobile ?
                                                    'error' :
                                                    ''
                                            }
                                            placeholder={'+417498564'}
                                            onChange={formik.handleChange}
                                        />
                                    </FormGuide>
                                </div>
                                <button
                                    type={'submit'}
                                    className={'submit button primary margin-top-2'}
                                >
                                    Weiter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

StudentForm.propTypes = {
    service: PropTypes.any
};
