'use strict';

import React        from 'react';
import PropTypes    from 'prop-types';
import {ResumeForm} from '../resumeForm';

export const ResumeFormContainer = ({applicationFormService}) => {
    const classTeacherMachine = applicationFormService.children.get('resumeFormMachine');

    return (
        <ResumeForm
            service={classTeacherMachine}
        />
    )
};

ResumeFormContainer.propTypes = {
    applicationFormService: PropTypes.any
}
