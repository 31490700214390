'use strict';

import React                   from 'react';
import PropTypes               from 'prop-types';
import {useActor, useSelector} from '@xstate/react';

import {DocumentUpload} from '../documentUpload';
import Loader           from '../loader';

export const RegistrationCompleteDocumentUploadContainer = ({service}) => {
    const documentUploadMachine = service.children.get('completeDocumentsUploadForm');
    const [state, send] = useActor(documentUploadMachine);

    const isLoading = useSelector(
        documentUploadMachine,
        state => state.matches('init')
    );

    const {
        texts = [],
        userCurrentData,
        error: errorObject = {}
    } = state.context;

    const {data = {}} = errorObject;
    const {details: errorDetails = {}} = data;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const {
        documents = []
    } = userCurrentData;

    const getDocumentsOutput = () => {
        return documents.map(({id, file_name: fileName, ecoWebDocId}) => {
            return (
                <div key={id} className="preview-actions">
                    <span
                        className="title"
                        title={fileName}
                    >
                        {fileName}
                    </span>

                    {/* Delete only not submitted files */}
                    {ecoWebDocId === 0 &&
                        <button
                            type={'button'}
                            className="link delete"
                            onClick={() => {
                                send({
                                    type: 'DELETEFILE',
                                    fileId: id
                                });
                            }}
                        >
                            Delete
                        </button>
                    }
                </div>
            );
        })
    };

    const getNavigation = () => {
        return (
            <>
                <button
                    type={'button'}
                    className={'clear button margin-top-2'}
                    onClick={() => {
                        send('BACK');
                    }}
                >
                    Abbrechen
                </button>
                <button
                    type={'submit'}
                    className={'submit button primary margin-top-2'}
                >
                    Hochladen
                </button>
            </>
        )
    };

    const submit = values => {
        return send(
            {
                type: 'SUBMIT',
                ...values
            }
        );
    };

    const addFiles = files => {
        return send({
            type: 'ADDFILES',
            files
        });
    };

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <DocumentUpload
                    send={send}
                    getDocumentsOutput={getDocumentsOutput}
                    getNavigation={getNavigation}
                    submit={submit}
                    addFiles={addFiles}
                    formText={texts}
                    userCurrentData={userCurrentData}
                    errorCodes={errorCodes}
                />
            }
        </>
    );
};

RegistrationCompleteDocumentUploadContainer.propTypes = {
    service: PropTypes.any
};
