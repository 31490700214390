'use strict';

import React                   from 'react';
import PropTypes               from 'prop-types';
import {useActor, useSelector} from '@xstate/react';
import Loader                  from '../loader';
import {useNavigate}           from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const VerifyEmail = ({service}) => {

    const [, send] = useActor(service);
    const navigate = useNavigate();

    const initial = useSelector(service, state => state.matches('verifyEmail.initial'));
    const onRegistration = useSelector(service, state => state.matches('verifyEmail.afterRegistration'));
    const loading = useSelector(service, state => state.matches('verifyEmail.requestNewEmail'));
    const successStatus = useSelector(service, state => state.matches('verifyEmail.requestNewEmailSuccess'));
    const errorStatus = useSelector(service, state => state.matches('verifyEmail.requestNewEmailError'));

    return (
        <>
            {loading &&
                <Loader />
            }
            {!loading &&
                <div className={'grid-container grid-y'}>
                    <div
                        className={[
                            'small-auto',
                            'large-padding-top_bottom',
                            'medium-padding-top_bottom',
                            'small-padding-top_bottom',
                            'content-container'
                        ].join(' ')}>

                        <div className={'margin-bottom-3'}>
                            <h1>
                                <strong>Bestätigung E-Mail-Adresse</strong>
                            </h1>
                        </div>

                        <div className={'grid-x grid-margin-x verify'}>
                            <div className={'grid-y cell auto'}>
                                {initial &&
                                    <>
                                        <p>
                                            Sie haben eine E-Mail zur Bestätigung Ihrer Registrierung erhalten.
                                            (Bitte überprüfen Sie auch den Spam Ordner).
                                            Folgen Sie den Anweisungen des E-Mails, danach können Sie sich anmelden.
                                        </p>

                                        <p>
                                            Falls Sie keine E-Mail erhalten haben, können Sie diese erneut anfordern.
                                        </p>

                                        <div>
                                            <button
                                                type={'button'}
                                                className={'button alert margin-top-2'}
                                                onClick={() => send('REQUEST_VALIDATION_EMAIL')}
                                            >
                                                Neue Bestätigungsmail anfordern
                                            </button>

                                            <button
                                                type={'button'}
                                                className={'button primary margin-top-2'}
                                                onClick={() => send('LOGOUT')}
                                            >
                                                Zur Anmeldung
                                            </button>
                                        </div>
                                    </>
                                }
                                {successStatus &&
                                    <>
                                        <p>
                                            Die E-Mail wurde erfolgreich versendet. Bitte überprüfen Sie auch
                                            den Spam Ordner und bestätigen Sie Ihre E-Mail-Adresse.
                                            Danach können Sie sich anmelden.
                                        </p>

                                        <div>
                                            <button
                                                type={'button'}
                                                className={'button primary margin-top-2'}
                                                onClick={() => send('LOGOUT')}
                                            >
                                                Zur Anmeldung
                                            </button>
                                        </div>
                                    </>
                                }
                                {errorStatus &&
                                    <>
                                        <p>
                                            Die E-Mail konnte nicht versendet werden.
                                            Bitte versuchen Sie es später noch einmal.
                                        </p>

                                        <div>
                                            <button
                                                type={'button'}
                                                className={'button alert margin-top-2'}
                                                onClick={() => send('REQUEST_VALIDATION_EMAIL')}
                                            >
                                                Neue Bestätigungsmail anfordern
                                            </button>
                                            <button
                                                type={'button'}
                                                className={'button primary margin-top-2'}
                                                onClick={() => send('LOGOUT')}
                                            >
                                                Zur Anmeldung
                                            </button>
                                        </div>
                                    </>
                                }
                                {onRegistration &&
                                    <>
                                        <p>
                                            Sie haben eine E-Mail zur Bestätigung Ihrer Registrierung erhalten.
                                            (Bitte überprüfen Sie auch den Spam Ordner).
                                            Folgen Sie den Anweisungen des E-Mails, danach können Sie sich anmelden.
                                        </p>

                                        <div>
                                            <button
                                                type={'button'}
                                                className={'button primary margin-top-2'}
                                                onClick={
                                                    () => {
                                                        send('DONE');
                                                        navigate('/');
                                                    }
                                                }
                                            >
                                                Zur Anmeldung
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

VerifyEmail.propTypes = {
    service: PropTypes.shape()
};
