'use strict';

import isObject from 'lodash.isobject';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const {createMachine, assign} = require('xstate');

const legalRepresentativeMachine = createMachine({
    id: 'legalRepresentativeForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        salutations: [],
        userCurrentData: {},
        error: {
            data: {}
        },
        userName: ''
    },
    states: {
        init: {
            entry: assign({
                userCurrentData: ({userCurrentData}) => ({
                    ...userCurrentData,
                    salutation: Array.isArray(userCurrentData.salutation) ? '' : userCurrentData.salutation.id,
                    firstName: userCurrentData.firstName || '',
                    lastName: userCurrentData.lastName || '',
                    street: userCurrentData.street || '',
                    streetNumber: userCurrentData.streetNumber || '',
                    zip: userCurrentData.zip || '',
                    city: userCurrentData.city || '',
                    mobile: userCurrentData.mobile || '',
                    phone: userCurrentData.phone || '',
                    email: userCurrentData.email || '',
                    hasAlternativeLegalRepresentative: !Array.isArray(userCurrentData.alternativeLegalRepresentative),
                    alternativeLegalRepresentativeSalutation:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.salutation.id || '' :
                            '',
                    alternativeLegalRepresentativeFirstName:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.firstName ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeLastName:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.lastName ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeStreet:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.street ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeStreetNumber:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.streetNumber ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeZip:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.zip ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeCity:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.city ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeMobile:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.mobile ||
                            ''  :
                            '',
                    alternativeLegalRepresentativeEmail:
                        !Array.isArray(userCurrentData.alternativeLegalRepresentative) ?
                            userCurrentData.alternativeLegalRepresentative.email ||
                            ''  :
                            '',
                })
            }),
            always: {
                target: 'edit'
            }
        },
        edit: {
            on: {
                SUBMIT: {
                    target: 'saveData',
                    actions: assign({
                        userCurrentData: ({userCurrentData}, event) => ({
                            ...userCurrentData,
                            salutation: event.salutation || '',
                            firstName: event.firstName || '',
                            lastName: event.lastName || '',
                            street: event.street || '',
                            streetNumber: event.streetNumber || '',
                            zip: event.zip || '',
                            city: event.city || '',
                            mobile: event.mobile || '',
                            phone: event.phone || '',
                            email: event.email || '',
                            hasAlternativeLegalRepresentative:
                                event.hasAlternativeLegalRepresentative || false,
                            alternativeLegalRepresentativeSalutation:
                                event.alternativeLegalRepresentativeSalutation || '',
                            alternativeLegalRepresentativeFirstName:
                                event.alternativeLegalRepresentativeFirstName || '',
                            alternativeLegalRepresentativeLastName:
                                event.alternativeLegalRepresentativeLastName || '',
                            alternativeLegalRepresentativeStreet:
                                event.alternativeLegalRepresentativeStreet || '',
                            alternativeLegalRepresentativeStreetNumber:
                                event.alternativeLegalRepresentativeStreetNumber || '',
                            alternativeLegalRepresentativeZip:
                                event.alternativeLegalRepresentativeZip || '',
                            alternativeLegalRepresentativeCity:
                                event.alternativeLegalRepresentativeCity || '',
                            alternativeLegalRepresentativeMobile:
                                event.alternativeLegalRepresentativeMobile || '',
                            alternativeLegalRepresentativeEmail:
                                event.alternativeLegalRepresentativeEmail || ''
                        })
                    })
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.legalRepresentativeSingleApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'PUT',
                    data: ({userCurrentData}) => {
                        // for some reason we got twice here :(
                        // the request is made only once though
                        if (userCurrentData.salutation === '') {
                            userCurrentData.salutation = [];
                        }

                        if (
                            !Array.isArray(userCurrentData.salutation) &&
                            !isObject(userCurrentData.salutation) &&
                            userCurrentData.salutation !== ''
                        ) {
                            userCurrentData.salutation = {
                                id: userCurrentData.salutation
                            };
                        }

                        userCurrentData.alternativeLegalRepresentative = [];
                        if (userCurrentData.hasAlternativeLegalRepresentative) {

                            if (
                                !Array.isArray(userCurrentData.alternativeLegalRepresentativeSalutation) &&
                                !isObject(userCurrentData.alternativeLegalRepresentativeSalutation) &&
                                userCurrentData.alternativeLegalRepresentativeSalutation !== ''
                            ) {
                                userCurrentData.alternativeLegalRepresentativeSalutation = {
                                    id: userCurrentData.alternativeLegalRepresentativeSalutation
                                };
                            }
                            userCurrentData.alternativeLegalRepresentative = {
                                salutation: userCurrentData.alternativeLegalRepresentativeSalutation,
                                firstName: userCurrentData.alternativeLegalRepresentativeFirstName || '',
                                lastName: userCurrentData.alternativeLegalRepresentativeLastName || '',
                                street: userCurrentData.alternativeLegalRepresentativeStreet || '',
                                streetNumber: userCurrentData.alternativeLegalRepresentativeStreetNumber || '',
                                zip: userCurrentData.alternativeLegalRepresentativeZip || '',
                                city: userCurrentData.alternativeLegalRepresentativeCity || '',
                                mobile: userCurrentData.alternativeLegalRepresentativeMobile || '',
                                email: userCurrentData.alternativeLegalRepresentativeEmail || '',
                            };
                        }

                        return {
                            ...userCurrentData,
                            editing: 0,
                            complete: 1
                        };
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => {
                            return data;
                        }
                    }),
                    target: 'done'
                },
                onError: {
                    target: 'edit'
                }
            }
        },
        done: {
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default legalRepresentativeMachine;
