'use strict';

import React from 'react';
import {
    Navigate,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider
}            from 'react-router-dom';
import {
    useInterpret,
    useSelector
}            from '@xstate/react';

import authMachine                    from '../../../machines/authMachine';
import fetchMachine                   from '../../../machines/fetchMachine';
import kttgMachine                    from '../../../machines/kttgApplicationMachine';
import applicationFormMachine         from '../../../machines/applicationFormMachine';
import schoolGoalMachine              from '../../../machines/schoolGoalMachine';
import legalRepresentativeMachine     from '../../../machines/legalRepresentativeMachine';
import studentMachine                 from '../../../machines/studentMachine';
import currenSchoolMachine            from '../../../machines/currenSchoolMachine';
import classTeacherMachine            from '../../../machines/classTeacherMachine';
import documentsUploadMachine         from '../../../machines/documentsUploadMachine';
import completeDocumentsUploadMachine from '../../../machines/completeDocumentsUploadMachine';
import dataOverviewMachine            from '../../../machines/dataOverviewMachine';
import resumeFormMachine              from '../../../machines/resumeFormMachine';

import Loader                  from '../loader';
import {SignInForm}            from '../login';
import {RegistrationContainer} from '../registrationContainer';
import {Header}                from '../header';
import {AuthorizedContainer}   from '../authorizedContainer';
import {VerifyEmailStatus}     from '../verifyEmailStatus';
import {ResetPassword}         from '../resetPassword';
import {ChangePasswordByToken} from '../changePasswordByToken';
import {Footer}                from '../footer';

const AppComponent = () => {

    const studentService = studentMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const legalRepresentativeService = legalRepresentativeMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const schoolGoalService = schoolGoalMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const currenSchoolService = currenSchoolMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const classTeacherService = classTeacherMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const documentsUploadService = documentsUploadMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const completeDocumentsUploadService = completeDocumentsUploadMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const dataOverviewService = dataOverviewMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const resumeFormService = resumeFormMachine.withConfig({
        services: {
            apiCall: fetchMachine
        }
    });

    const formMachine = applicationFormMachine.withConfig({
        services: {
            apiCall: fetchMachine,
            studentForm: studentService,
            legalRepresentativeForm: legalRepresentativeService,
            schoolGoalForm: schoolGoalService,
            currentSchoolForm: currenSchoolService,
            classTeacherForm: classTeacherService,
            documentsUploadForm: documentsUploadService,
            dataOverviewForm: dataOverviewService,
            resumeFormMachine: resumeFormService
        }
    });

    const kttgApplicationMachine = kttgMachine.withConfig({
        services: {
            applicationForm: formMachine,
            apiCall: fetchMachine,
            completeDocumentsUploadForm: completeDocumentsUploadService
        }
    });

    const authService = useInterpret(
        authMachine,
        {
            services: {
                apiCall: fetchMachine,
                loginUser: fetchMachine,
                kttgApplication: kttgApplicationMachine
            }
        }
    );

    const isAuthorized = useSelector(authService, state => state.matches('authorized'));
    const isLoading = useSelector(authService, state => state.matches('init'));
    const unauthorizedRoutes = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/registration"
                    replace={true}
                    element={
                        <RegistrationContainer />
                    }
                />
                <Route
                    path="/"
                    element={
                        <SignInForm
                            authService={authService}
                        />
                    }
                />
                <Route
                    path="/verify-email/:status"
                    element={
                        <VerifyEmailStatus/>
                    }
                />
                <Route
                    path="/reset-password"
                    replace={true}
                    element={
                        <ResetPassword
                            authService={authService}
                        />
                    }
                />
                <Route
                    path="/change-password/:token"
                    element={
                        <ChangePasswordByToken
                            authService={authService}
                        />
                    }
                />
                <Route
                    path="/*"
                    element={
                        <Navigate
                            to={'/'}
                            replace={true}
                        />
                    }
                />
            </>
        )
    );

    return (
        <div className={'app-container wrapper'}>
            <Header
                authService={authService}
            />
            {
                isLoading &&
                <Loader/>
            }
            {!isLoading && isAuthorized &&
                <AuthorizedContainer
                    authService={authService}
                />
            }
            {!isLoading && !isAuthorized &&
                <RouterProvider router={unauthorizedRoutes}/>
            }
            <Footer />
        </div>
    );
}

export default function App() {
    return (
        <AppComponent/>
    );
}
