'use strict';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';
import isObject  from 'lodash.isobject';

const {createMachine, assign} = require('xstate');

const classTeacherMachine = createMachine({
    id: 'classTeacherForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        salutations: [],
        userCurrentData: {},
        error: {
            data: {}
        },
        userName: ''
    },
    states: {
        init: {
            entry: assign({
                userCurrentData: ({userCurrentData}) => ({
                    ...userCurrentData,
                    salutation: Array.isArray(userCurrentData.salutation) ? '' : userCurrentData.salutation.id,
                    firstName: userCurrentData.firstName || '',
                    lastName: userCurrentData.lastName || '',
                    street: userCurrentData.street || '',
                    streetNumber: userCurrentData.streetNumber || '',
                    zip: userCurrentData.zip || '',
                    city: userCurrentData.city || '',
                    mobile: userCurrentData.mobile || '',
                    phone: userCurrentData.phone || '',
                    email: userCurrentData.email || '',
                })
            }),
            always: {
                target: 'edit'
            }
        },
        edit: {
            on: {
                SUBMIT: {
                    target: 'saveData',
                    actions: assign({
                        userCurrentData: ({userCurrentData}, event) => ({
                            ...userCurrentData,
                            salutation: event.salutation || '',
                            firstName: event.firstName || '',
                            lastName: event.lastName || '',
                            street: event.street || '',
                            streetNumber: event.streetNumber || '',
                            zip: event.zip || '',
                            city: event.city || '',
                            mobile: event.mobile || '',
                            phone: event.phone || '',
                            email: event.email || ''
                        })
                    })
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentData}) => {
                        return api.classTeacherSingleApiUrlTemplate(userCurrentData.id)
                    },
                    method: 'PUT',
                    data: ({userCurrentData}) => {

                        if (userCurrentData.salutation === '') {
                            userCurrentData.salutation = [];
                        }

                        if (
                            !Array.isArray(userCurrentData.salutation) &&
                            !isObject(userCurrentData.salutation) &&
                            userCurrentData.salutation !== ''
                        ) {
                            userCurrentData.salutation = {
                                id: userCurrentData.salutation
                            };
                        }

                        return {
                            ...userCurrentData,
                            editing: 0,
                            complete: 1
                        };
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentData: (_, {data}) => data
                    }),
                    target: 'done'
                },
                onError: {
                    actions: assign({
                        error: (_, {data}) => ({data})
                    }),
                    target: 'edit'
                }
            }
        },
        done: {
            type: 'final',
            data: ({userCurrentData}) => userCurrentData
        }
    }
});

export default classTeacherMachine;
