'use strict';

import React                   from 'react';
import {useActor, useSelector} from '@xstate/react';
import {useTranslation}        from 'react-i18next';
import Modal                   from 'sfp-react-modal';
import PropTypes               from 'prop-types';

import warningImg from '../../assets/images/icons/warning.svg';
import Loader     from '../loader';

export const ResetDataModal = ({authService}) => {
    const [, send] = useActor(authService);
    const {t} = useTranslation();

    const inResetUserData = useSelector(
        authService,
        state => (
            state.matches('registrationComplete.resetUserData')
        )
    );

    const inResetDataConfirmation = useSelector(
        authService,
        state => (
            state.matches('registrationComplete.resetUserData.confirmation')
        )
    );

    const inResetDataProcessing = useSelector(
        authService,
        state => (
            state.matches('registrationComplete.resetUserData.processing')
        )
    );

    const inResetDataError = useSelector(
        authService,
        state => (
            state.matches('registrationComplete.resetUserData.error')
        )
    );

    const inResetDataSuccess = useSelector(
        authService,
        state => (
            state.matches('registrationComplete.resetUserData.success')
        )
    );

    const confirmSubmission = () => {
        return send({
            type: 'CONFIRM'
        });
    };

    const resetDataSuccess = () => {
        return send({
            type: 'CONTINUE'
        });
    };

    const closeModal = () => {
        if (inResetDataSuccess) {
            return resetDataSuccess();
        }

        return send({
            type: 'CANCEL'
        });
    };

    const confirmationModalContent = () => {
        return (
            <div className={'confirmation grid-x grid-margin-x'}>
                <div className={'cell full-width'}>
                    <h3>
                        <img
                            className={'resetDataWarningImage'}
                            alt={''}
                            src={warningImg}
                        />
                        {t('resetDataWarningTitle')}
                    </h3>
                </div>
                <div className={'cell full-width'}>
                    <p>{t('resetDataWarningText')}</p>
                </div>
                <div className={'cell full-width'}>
                    <div className={'grid-x  align-justify'}>
                        <div className={'cell shrink'}>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}
                                className={'button button-cancel clear'}
                                href={'#'}
                            >
                                {t('cancel')}
                            </a>
                        </div>
                        <div className={'cell shrink'}>
                            <button
                                onClick={confirmSubmission}
                                className={'button button-confirm'}
                            >
                                {t('resetDataConfirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const errorModalContent = () => {
        return (
            <div className={'confirmation grid-x grid-margin-x'}>
                <div className={'cell full-width'}>
                    <h3>
                        <img
                            className={'resetDataWarningImage'}
                            alt={''}
                            src={warningImg}
                        />
                        {t('resetDataWarningTitle')}
                    </h3>
                </div>
                <div className={'cell full-width'}>
                    <p>{t('resetDataGeneralError')}</p>
                </div>
                <div className={'cell full-width'}>
                    <div className={'grid-x  align-justify'}>
                        <div className={'cell shrink'}>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}
                                className={'button button-cancel clear'}
                                href={'#'}
                            >
                                {t('cancel')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const successModalContent = () => {
        return (
            <div className={'confirmation grid-x grid-margin-x'}>
                <div className={'cell full-width'}>
                    <h3>
                        <img
                            className={'resetDataWarningImage'}
                            alt={''}
                            src={warningImg}
                        />
                        {t('resetDataWarningTitle')}
                    </h3>
                </div>
                <div className={'cell full-width'}>
                    <p>{t('resetDataSuccessText')}</p>
                </div>
                <div className={'cell full-width'}>
                    <div className={'grid-x  align-justify'}>
                        <div className={'cell shrink'}>
                            <div className={'cell shrink'}>
                                <button
                                    onClick={resetDataSuccess}
                                    className={'button button-confirm'}
                                >
                                    {t('continue')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            id={'reset-data-modal'}
            isOpen={inResetUserData}
            escClose={false}
            backgroundClickClose={false}
            closeHandler={closeModal}
            customClasses={'reset-data-modal'}
        >
            <div className={'reset-data-content'}>
                <div className={'grid-container fluid'}>
                    <button
                        onClick={closeModal}
                        className={'close-button'}
                    >
                        <span aria-hidden={'true'}>
                            &times;
                        </span>
                    </button>
                    {inResetDataConfirmation &&
                        confirmationModalContent()
                    }
                    {inResetDataError &&
                        errorModalContent()
                    }
                    {inResetDataProcessing &&
                        <Loader />
                    }
                    {inResetDataSuccess &&
                        successModalContent()
                    }
                </div>
            </div>
        </Modal>
    );
};

ResetDataModal.propTypes = {
    authService: PropTypes.shape()
};
