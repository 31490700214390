import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import React                                   from 'react'

const passwordReveal = (input, setInputType) => {
    if (input === 'password') {
        return <AiOutlineEye className='password-toggle' onClick={() => setInputType('text')} />
    }

    return <AiOutlineEyeInvisible className='password-toggle' onClick={() => setInputType('password')} />
}

export default passwordReveal;