'use strict';

import React, {useState}                   from 'react';
import {useActor, useSelector}             from '@xstate/react';
import PropTypes                           from 'prop-types';
import {object, ref, string}               from 'yup';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useNavigate, useParams}            from 'react-router-dom';

import {FormGuide}      from '../formGuide';
import Loader           from '../loader';
import {password}       from '../conf';
import passwordReveal   from '../../lib/passwordReveal';
import {useTranslation} from 'react-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ChangePasswordByToken = ({authService}) => {

    const [state, send] = useActor(authService);
    const [inputType, setInputType] = useState('password');
    const {token} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {error: errorObject = {}} = state.context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = [],
        password: passwordError = [],
        passwordRepeat: passwordRepeatError = []
    } = codes;

    const isLoading = useSelector(authService, state => {
        return state.matches('changePassword.changePassword')
    });

    const inEditStep = useSelector(authService, state => (
        state.matches('changePassword.edit')
    ));

    const inChangePasswordSuccessStep = useSelector(authService, state => (
        state.matches('changePassword.changePasswordSuccess')
    ));

    const inChangePasswordErrorStep = useSelector(authService, state => (
        state.matches('changePassword.changePasswordError')
    ));

    const payload = {
        password: '',
        passwordRepeat: ''
    };

    const validationSchema = object({
        password: string()
            .min(password.minLength, t('passwordLengthMessage', {length: password.minLength}))
            .required(t('passwordRequiredMessage')),
        passwordRepeat: string()
            .required(t('passwordRepeatRequiredMessage'))
            .oneOf([ref('password'), null], t('passwordRepeatMismatchMessage'))
    });

    const submit = payload => {
        return send({
            type: 'SUBMIT',
            ...payload,
            passwordResetToken: token
        });
    };

    return (
        <div>
            {isLoading &&
                <Loader/>
            }
            {inEditStep &&
                <Formik
                    initialValues={payload}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                >
                    {({errors, touched}) => {
                        return (
                            <div className={'grid-container grid-y'}>
                                <div
                                    className={[
                                        'small-auto',
                                        'large-padding-top_bottom',
                                        'medium-padding-top_bottom',
                                        'small-padding-top_bottom',
                                        'content-container'
                                    ].join(' ')}>

                                    <div className={'margin-bottom-3'}>
                                        <h1>
                                            <strong>{t('changePassword')}</strong>
                                        </h1>
                                    </div>

                                    <Form>
                                        <div>

                                            {errorCodes &&
                                                <div className={'errors'}>
                                                    {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                                </div>
                                            }

                                            <FormGuide html={''}>
                                                <label htmlFor={'password'}>{t('password')}</label>
                                                {passwordReveal(inputType, setInputType)}
                                                <ErrorMessage component="div" name="password" className="errors"/>
                                                {
                                                    passwordError.length ?
                                                        <div className={'errors'}>
                                                            {t(passwordError[0])}
                                                        </div> :
                                                        null
                                                }
                                                <Field
                                                    name={'password'}
                                                    type={inputType}
                                                    id={'password'}
                                                    className={
                                                        (errors.password && touched.password) || passwordError.length ?
                                                            'error' :
                                                            ''
                                                    }
                                                />
                                            </FormGuide>

                                            <FormGuide html={''}>
                                                <label htmlFor={'passwordRepeat'}>{t('passwordRepeat')}</label>
                                                {passwordReveal(inputType, setInputType)}
                                                <ErrorMessage component="div" name="passwordRepeat" className="errors"/>
                                                {
                                                    passwordRepeatError.length ?
                                                        <div className={'errors'}>
                                                            {t(passwordRepeatError[0])}
                                                        </div> :
                                                        null
                                                }
                                                <Field
                                                    name={'passwordRepeat'}
                                                    type={inputType}
                                                    className={
                                                        (errors.passwordRepeat && touched.passwordRepeat) ||
                                                        passwordRepeatError.length ?
                                                            'error' :
                                                            ''
                                                    }
                                                />
                                            </FormGuide>
                                            <button
                                                type={'submit'}
                                                className={'submit button primary margin-top-2'}
                                            >
                                                {t('changePassword')}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            }
            {inChangePasswordSuccessStep &&
                <div className={'grid-container grid-y'}>
                    <div
                        className={[
                            'small-auto',
                            'large-padding-top_bottom',
                            'medium-padding-top_bottom',
                            'small-padding-top_bottom',
                            'content-container'
                        ].join(' ')}
                    >
                        <div className={'margin-bottom-3'}>
                            <h1>
                                <strong>{t('changePassword')}</strong>
                            </h1>
                        </div>
                        <div>{t('changePasswordSuccessMessage')}</div>
                        <div>{t('changePasswordSuccessMessage1')}</div>
                        <button
                            className={'primary button margin-top-2'}
                            onClick={() => {
                                send('BACK');
                                navigate('/');
                            }}
                        >
                            {t('backToLogin')}
                        </button>
                    </div>
                </div>
            }
            {inChangePasswordErrorStep &&
                <div className={'grid-container grid-y'}>
                    <div
                        className={[
                            'small-auto',
                            'large-padding-top_bottom',
                            'medium-padding-top_bottom',
                            'small-padding-top_bottom',
                            'content-container'
                        ].join(' ')}
                    >
                        <div className={'margin-bottom-3'}>
                            <h1>
                                <strong>{t('changePassword')}</strong>
                            </h1>
                        </div>
                        <div>{t('changePasswordErrorMessage1')}</div>
                        <div>{t('changePasswordErrorMessage2')}</div>
                        <button
                            className={'primary button margin-top-2'}
                            onClick={() => {
                                send('BACK');
                                navigate('/');
                            }}
                        >
                            {t('backToLogin')}
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

ChangePasswordByToken.propTypes = {
    authService: PropTypes.shape()
};
