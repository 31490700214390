'use strict';

const {createMachine, assign, actions} = require('xstate');
const axios = require('axios').default;

const maxRetries = 0;
const {escalate} = actions;

const fetchMachine = createMachine(
    {
        id: 'apiCall',
        predictableActionArguments: true,
        initial: 'fetching',
        context: {
            data: {},
            url: null,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            response: null,
            errorMessage: null,
            retriesCount: 0
        },
        states: {
            fetching: {
                invoke: {
                    src: ({method, url, data, headers}) => {
                        return axios({
                            method,
                            url,
                            data,
                            headers,
                            timeout: 45000
                        })
                            .then(response => {
                                if (response.status !== 200) {
                                    return Promise.reject(response.data);
                                }

                                return response.data;
                            })
                    },
                    onDone: {
                        target: 'done',
                        actions: assign({
                            response: (_, {data}) => data
                        })
                    },
                    onError: {
                        target: 'retry',
                        actions: assign({
                            errorMessage: (_, {data}) => data.response || data,
                            retriesCount: ({retriesCount}, {data}) => {
                                const {response = {}} = data;

                                // retry only on server errors
                                if (response.status && response.status < 500) {
                                    return maxRetries;
                                }
                                return retriesCount + 1
                            }
                        })
                    }
                }
            },
            retry: {
                invoke: {
                    src: ({errorMessage, retriesCount}) => {
                        if (retriesCount >= maxRetries) {
                            return Promise.reject(errorMessage.data || '');
                        }

                        return Promise.resolve();
                    },
                    onDone: {
                        target: 'fetching'
                    },
                    onError: {
                        actions: escalate((_, {data}) => ({
                            ...data.error
                        }))
                    }
                }
            },
            done: {
                type: 'final',
                data: {
                    response: ({response}) => response
                }
            }
        }
    }
);

export default fetchMachine;
