'use strict';

import i18n               from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend            from 'i18next-http-backend';

import cfg from './lib/conf';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init(cfg);

export default i18n;
