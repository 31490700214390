'use strict';

import React                       from 'react';
import {useInterpret, useSelector} from '@xstate/react';

import fetchMachine                from '../../../machines/fetchMachine';
import registrationMachine         from '../../../machines/registrationMachine';
import Loader                      from '../loader';
import {RegistrationForm}          from '../registrationForm';
import {VerifyEmail}               from '../verifyEmail';
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const RegistrationContainer = () => {

    const registrationService = useInterpret(registrationMachine, {
        services: {
            apiCall: fetchMachine
        }
    });

    const isRegistering = useSelector(registrationService, state => state.matches('registration'));
    const isLoading = useSelector(registrationService, state => state.matches('init'));
    const isDone = useSelector(registrationService, state => state.matches('verifyEmail'));

    return (
        <>
            {
                isLoading &&
                <Loader/>
            }
            {
                isRegistering &&
                <div className={'grid-container grid-y'}>
                    <div
                        className={[
                            'small-auto',
                            'large-padding-top_bottom',
                            'medium-padding-top_bottom',
                            'small-padding-top_bottom',
                            'content-container'
                        ].join(' ')}>
                        <div className={'margin-bottom-3'}>
                            <h1>
                                <strong>Kandidatin / Kandidat</strong>
                            </h1>
                        </div>
                        <div className={'grid-x grid-margin-x'}>
                            <div className={'grid-y cell auto'}>
                                <RegistrationForm
                                    registrationService={registrationService}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isDone &&
                <VerifyEmail
                    service={registrationService}
                />
            }
        </>
    );
};
