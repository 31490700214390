'use strict';

const cfg = {
    ns: ['translation'],
    defaultNS: 'translation',
    locales: ['de'],
    localeMapping: {
        de: 0
    },
    preload: ['de'],
    whitelist: ['de'],
    fallbackLng: {
        'de-CH': ['de'],
        'de-DE': ['de'],
        'default': ['de']
    },
    saveMissing: false,
    nsSeparator: ':::',
    keySeparator: '::',
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
    backend: {
        loadPath: '/static/locales/{{lng}}/{{ns}}.json',
        addPath: '/debug/locales/add/{{lng}}/{{ns}}'
    }
};

export default cfg;
