'use strict';

import {context} from './lib/fetchMachineContext';
import {api}     from './lib/conf';

const {createMachine, assign} = require('xstate');

const schoolGoalMachine = createMachine({
    id: 'schoolGoalForm',
    predictableActionArguments: true,
    initial: 'init',
    context: {
        data: {},
        userCurrentSchoolGoalData: {},
        enteredData: {
            originSchool: '',
            targetSchoolType: '',
            targetSchoolCity: '',
            targetSchool: '',
            specification: '',
            talent: ''
        },
        enabledModules: {
            originSchool: true,
            targetSchoolType: false,
            targetSchoolCity: false,
            specification: false,
            talent: false
        },
        error: {
            data: {}
        },
        userName: ''
    },
    states: {
        init: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.schoolGoalsPossibilitiesApiUrlTemplate()
                },
                onDone: {
                    target: 'resumeForm',
                    actions: assign({
                        data: (_, {data}) => data.response
                    })
                },
                onError: {
                }
            }
        },
        resumeForm: {
            entry: assign({
                enteredData: ({userCurrentSchoolGoalData, error}) => {
                    const {
                        originSchool,
                        targetSchool,
                        targetSchoolType,
                        targetSchoolCity,
                        specification,
                        talent
                    } = userCurrentSchoolGoalData;

                    const hasError = error.data && error.data.error;

                    return {
                        originSchool: Array.isArray(originSchool) || hasError ? '' : originSchool,
                        targetSchoolType: Array.isArray(targetSchoolType) || hasError ? '' : targetSchoolType,
                        targetSchoolCity: Array.isArray(targetSchoolCity) || hasError ? '' : targetSchoolCity,
                        targetSchool: Array.isArray(targetSchool) || hasError ? '' : targetSchool,
                        specification: Array.isArray(specification) || hasError ? '' : specification,
                        talent: Array.isArray(talent) || hasError ? '' : talent
                    }
                },
                enabledModules: ({userCurrentSchoolGoalData, error}) => {
                    const {
                        targetSchool,
                        targetSchoolType,
                        targetSchoolCity
                    } = userCurrentSchoolGoalData;

                    const hasError = error.data && error.data.error;

                    // empty objects are empty arrays
                    return {
                        originSchool: true,
                        targetSchoolType: !Array.isArray(targetSchoolType) && !hasError,
                        targetSchoolCity: !Array.isArray(targetSchoolCity) && !hasError,
                        targetSchool: !Array.isArray(targetSchool) && !hasError,
                        specification: !Array.isArray(targetSchoolCity) && !hasError,
                        talent: !Array.isArray(targetSchoolCity) && !hasError
                    }
                },
                error: ({error}) => {

                    return {
                        data: error ? error.data : {}
                    };
                }
            }),
            always: {
                target: 'edit'
            }
        },
        edit: {
            on: {
                ORIGIN_SCHOOL_CHANGE: {
                    actions: assign({
                        enteredData: (_, {value}) => {
                            return {
                                originSchool: value,
                                targetSchoolType: '',
                                targetSchoolCity: '',
                                targetSchool: '',
                                specification: '',
                                talent: ''
                            }
                        },
                        enabledModules: {
                            originSchool: true,
                            targetSchoolType: true,
                            targetSchoolCity: false,
                            specification: false,
                            talent: false
                        }
                    })
                },
                TARGET_SCHOOL_TYPE_CHANGE: {
                    actions: assign({
                        enteredData: ({enteredData}, {value}) => ({
                            ...enteredData,
                            targetSchoolType: value,
                            targetSchoolCity: '',
                            targetSchool: '',
                            specification: '',
                            talent: ''
                        }),
                        enabledModules: {
                            originSchool: true,
                            targetSchoolType: true,
                            targetSchoolCity: true,
                            specification: false,
                            talent: false
                        }
                    })
                },
                TARGET_SCHOOL_CITY_CHANGE: {
                    actions: assign({
                        enteredData: ({enteredData}, {value}) => ({
                            ...enteredData,
                            targetSchoolCity: {
                                id: value.id,
                                name: value.name
                            },
                            targetSchool: {
                                id: value.targetSchoolId,
                                // this is hack. Otherwise, the serializer does not work as expected
                                name: enteredData.targetSchoolType.name
                            },
                            specification: '',
                            talent: ''
                        }),
                        enabledModules: {
                            originSchool: true,
                            targetSchoolCity: true,
                            targetSchoolType: true,
                            specification: true,
                            talent: true
                        }
                    })
                },
                SPECIFICATION_CHANGE: {
                    actions: assign({
                        enteredData: ({enteredData}, {value}) => ({
                            ...enteredData,
                            specification: value,
                            talent: ''
                        })
                    })
                },
                TALENT_CHANGE: {
                    actions: assign({
                        enteredData: ({enteredData}, {value}) => ({
                            ...enteredData,
                            talent: value
                        })
                    })
                },
                SUBMIT: {
                    target: 'saveData'
                }
            }
        },
        saveData: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: ({userCurrentSchoolGoalData}) => {
                        return api.schoolGoalSingleApiUrlTemplate(userCurrentSchoolGoalData.id)
                    },
                    method: 'PUT',
                    data: ({enteredData}) => {
                        if (enteredData.specification === '') {
                            enteredData.specification = [];
                        }
                        if (enteredData.talent === '') {
                            enteredData.talent = [];
                        }

                        return {
                            ...enteredData,
                            editing: 0,
                            complete: 1
                        };
                    }
                },
                onDone: {
                    actions: assign({
                        userCurrentSchoolGoalData: (_, {data}) => data
                    }),
                    target: 'done'
                },
                onError: {
                    target: 'edit'
                }
            }
        },
        done: {
            type: 'final',
            data: ({userCurrentSchoolGoalData}) => userCurrentSchoolGoalData
        }
    }
});

export default schoolGoalMachine;
