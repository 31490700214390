'use strict';

import React              from 'react';
import PropTypes          from 'prop-types';
import {useSelector}      from '@xstate/react';
import Loader             from '../loader';
import {ClassTeacherForm} from '../classTeacherForm';

export const ClassTeacherContainer = ({applicationFormService}) => {
    const classTeacherMachine = applicationFormService.children.get('classTeacherForm');

    const isLoading = useSelector(
        classTeacherMachine,
        state => state.matches('init')
    );

    return (
        <>
            {isLoading &&
                <Loader/>
            }
            {!isLoading &&
                <ClassTeacherForm
                    service={classTeacherMachine}
                />
            }
        </>
    )
};

ClassTeacherContainer.propTypes = {
    applicationFormService: PropTypes.any
}
