'use strict';

import {createMachine, assign, send} from 'xstate';

import {context}                 from './lib/fetchMachineContext';
import {api}                     from './lib/conf';
import schoolMunicipalityMapping from './lib/schoolMunicipalityMapping.json';
import calculateStep             from './lib/calculateStep';

const getName = user => {
    const {student} = user.schoolApplication || {};

    if (student.useStudentData) {
        return `${student.firstName} ${student.lastName}`;
    }

    return `${user.firstName} ${user.lastName}`
};

const applicationFormMachine = createMachine({
    id: 'applicationForm',
    predictableActionArguments: true,
    initial: 'getSalutations',
    context: {
        user: null,
        registrationData: {},
        salutations: [],
        languages: [],
        countries: [],
        texts: [],
        error: {
            data: {}
        },
        stepsCount: 0
    },
    states: {
        getSalutations: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.salutationsApiUrlTemplate()
                },
                onDone: {
                    target: 'getLanguages',
                    actions: assign({
                        salutations: (_, {data}) => data.response,
                        stepsCount: 7
                    })
                },
                onError: {}
            }
        },
        getLanguages: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.languagesApiUrlTemplate()
                },
                onDone: {
                    target: 'getCountries',
                    actions: assign({
                        languages: (_, {data}) => data.response
                    })
                },
                onError: {}
            }
        },
        getCountries: {
            invoke: {
                src: 'apiCall',
                data: {
                    ...context,
                    url: api.countriesApiUrlTemplate()
                },
                onDone: {
                    target: 'resumeForm',
                    actions: assign({
                        countries: (_, {data}) => data.response
                    })
                },
                onError: {}
            }
        },
        resumeForm: {
            id: 'resumeForm',
            invoke: {
                src: ({registrationData}) => callback => {
                    calculateStep(registrationData, callback);
                }
            },
            on: {
                RESUMEFORMONLOGIN: {
                    target: 'resumeFormOnLogin'
                },
                STUDENTDATA: {
                    target: 'studentData'
                },
                PARENTSDATA: {
                    target: 'parentsData'
                },
                SCHOOLGOALDATA: {
                    target: 'schoolGoalData'
                },
                CURRENTSCHOOLDATA: {
                    target: 'currentSchoolData'
                },
                TEACHERDATA: {
                    target: 'teacherData'
                },
                DOCUMENTSUPLOAD: {
                    target: 'documentsUpload'
                },
                DATAOVERVIEW: {
                    target: 'dataOverview'
                },
                REGISTRATIONCOMPLETE: {
                    target: 'registrationComplete'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        resumeFormOnLogin: {
            invoke: {
                id: 'resumeFormMachine',
                src: 'resumeFormMachine',
                data: {
                    userCurrentData: ({registrationData}) => registrationData || {},
                    userName: ({user}) => getName(user)
                },
                onDone: {
                    target: 'resumeForm',
                    actions: assign({
                        registrationData: (_, {data}) => data.response,
                        user: ({registrationData, user}) => {
                            user.schoolApplication = registrationData;
                            return user;
                        }
                    })
                },
                onError: {}
            },
            on: {
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        studentData: {
            id: 'studentData',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.studentSingleApiUrlTemplate(registrationData.student.id);
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyStudentData = Object.entries(registrationData.student)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyStudentData,
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    student: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    },
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('student')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'studentForm',
                        src: 'studentForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.student || {},
                            salutations: ({salutations}) => salutations || {},
                            user: ({user}) => user || {},
                            languages: ({languages}) => languages || {},
                            countries: ({countries}) => countries || {},
                            texts: ({texts}) => texts || {}
                        },
                        onDone: {
                            target: '#parentsData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    student: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;
                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                }
            },
            on: {
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        parentsData: {
            id: 'parentsData',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.legalRepresentativeSingleApiUrlTemplate(
                                    registrationData.legalRepresentative.id
                                );
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyLegalRepresentativeData = Object.entries(
                                    registrationData.legalRepresentative
                                )
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyLegalRepresentativeData,
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    legalRepresentative: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('parent')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'legalRepresentativeForm',
                        src: 'legalRepresentativeForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.legalRepresentative || {},
                            salutations: ({salutations}) => salutations || {},
                            texts: ({texts}) => texts || {},
                            userName: ({user}) => getName(user)
                        },
                        onDone: {
                            target: '#schoolGoalData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    legalRepresentative: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                back: {
                    id: 'parentsDataBack',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.legalRepresentativeSingleApiUrlTemplate(
                                    registrationData.legalRepresentative.id
                                );
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyLegalRepresentativeData = Object.entries(
                                    registrationData.legalRepresentative
                                )
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyLegalRepresentativeData,
                                    editing: 0,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: '#studentData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    legalRepresentative: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                }
            },
            on: {
                BACK: {
                    target: '#parentsDataBack'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        schoolGoalData: {
            id: 'schoolGoalData',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => (
                                api.schoolGoalSingleApiUrlTemplate(registrationData.schoolGoal.id)
                            ),
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptySchoolGoalData = Object.entries(registrationData.schoolGoal)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value)) {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptySchoolGoalData,
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    schoolGoal: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'getTexts'
                        }
                    }
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('schoolGoal')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'schoolGoalForm',
                        src: 'schoolGoalForm',
                        data: {
                            userCurrentSchoolGoalData: ({registrationData}) => registrationData.schoolGoal,
                            texts: ({texts}) => texts || {},
                            userName: ({user}) => getName(user),
                            error: ({error}) => error || {}
                        },
                        onDone: {
                            target: '#currentSchoolData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    schoolGoal: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                back: {
                    id: 'schoolGoalDataBack',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => (
                                api.schoolGoalSingleApiUrlTemplate(registrationData.schoolGoal.id)
                            ),
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptySchoolGoalData = Object.entries(registrationData.schoolGoal)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value)) {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptySchoolGoalData,
                                    editing: 0,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: '#parentsData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    schoolGoal: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: '#parentsData',
                        }
                    }
                }
            },
            on: {
                BACK: {
                    target: '#schoolGoalDataBack'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        currentSchoolData: {
            id: 'currentSchoolData',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.currentSchoolSingleApiUrlTemplate(registrationData.currentSchool.id);
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyCurrentSchoolData = Object.entries(registrationData.currentSchool)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyCurrentSchoolData,
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    currentSchool: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('currentSchool')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'currentSchoolForm',
                        src: 'currentSchoolForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.currentSchool || {},
                            schoolMunicipalityMapping,
                            texts: ({texts}) => texts || {},
                            error: {
                                data: {}
                            },
                            userName: ({user}) => getName(user)
                        },
                        onDone: {
                            target: '#teacherData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    currentSchool: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                back: {
                    id: 'currentSchoolDataBack',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.currentSchoolSingleApiUrlTemplate(registrationData.currentSchool.id);
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyCurrentSchoolData = Object.entries(registrationData.currentSchool)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyCurrentSchoolData,
                                    editing: 0,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: '#schoolGoalData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    currentSchool: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                }
            },
            on: {
                BACK: {
                    target: '#currentSchoolDataBack'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        teacherData: {
            entry: assign({
                error: {data: {}}
            }),
            id: 'teacherData',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.classTeacherSingleApiUrlTemplate(registrationData.classTeacher.id);
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyClassTeacherData = Object.entries(registrationData.classTeacher)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyClassTeacherData,
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    classTeacher: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'getTexts'
                        }
                    }
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('teacher')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'editing'
                        }

                    }
                },
                editing: {
                    invoke: {
                        id: 'classTeacherForm',
                        src: 'classTeacherForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.classTeacher || {},
                            salutations: ({salutations}) => salutations || {},
                            texts: ({texts}) => texts || {},
                            error: ({error}) => error,
                            userName: ({user}) => getName(user)
                        },
                        onDone: {
                            target: '#documentsUpload',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    classTeacher: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'editing'
                        }
                    }
                },
                back: {
                    id: 'teacherDataBack',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.classTeacherSingleApiUrlTemplate(registrationData.classTeacher.id);
                            },
                            method: 'PUT',
                            data: ({registrationData}) => {
                                const notEmptyClassTeacherData = Object.entries(registrationData.classTeacher)
                                    .reduce((prev, [key, value]) => {
                                        if (value !== null && !Array.isArray(value) && value !== '') {
                                            prev[key] = value;
                                        }
                                        return prev;
                                    }, {});
                                return {
                                    ...notEmptyClassTeacherData,
                                    editing: 0,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: '#currentSchoolData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    classTeacher: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'editing'
                        }
                    }
                }
            },
            on: {
                BACK: {
                    target: '#teacherDataBack'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        documentsUpload: {
            id: 'documentsUpload',
            initial: 'entrance',
            states: {
                entrance: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.documentsUploadSingleApiUrlTemplate(registrationData.documentsUpload.id);
                            },
                            method: 'PUT',
                            data: () => {
                                return {
                                    editing: 1,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: 'getTexts',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    documentsUpload: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('documentsUpload')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'documentsUploadForm',
                        src: 'documentsUploadForm',
                        data: {
                            userCurrentData: ({registrationData}) => registrationData.documentsUpload || {},
                            user: ({user}) => user || {},
                            texts: ({texts}) => texts || {},
                            userName: ({user}) => getName(user)
                        },
                        onDone: {
                            target: '#dataOverview',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    documentsUpload: data.response
                                }),
                                user: ({registrationData, user}, {data}) => {
                                    registrationData.documentsUpload = data.response;
                                    user.schoolApplication = registrationData;
                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                },
                back: {
                    id: 'documentsUploadBack',
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: ({registrationData}) => {
                                return api.documentsUploadSingleApiUrlTemplate(registrationData.classTeacher.id);
                            },
                            method: 'PUT',
                            data: () => {
                                return {
                                    editing: 0,
                                    complete: 0
                                }
                            }
                        },
                        onDone: {
                            target: '#teacherData',
                            actions: assign({
                                registrationData: ({registrationData}, {data}) => ({
                                    ...registrationData,
                                    documentsUpload: data.response
                                }),
                                user: ({registrationData, user}) => {
                                    user.schoolApplication = registrationData;

                                    return user;
                                }
                            })
                        },
                        onError: {}
                    }
                }
            },
            on: {
                BACK: {
                    target: '#documentsUploadBack'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                }
            }
        },
        dataOverview: {
            id: 'dataOverview',
            initial: 'getTexts',
            states: {
                getTexts: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.formTextsSingleApiUrlTemplate('dataOverview')
                        },
                        onDone: {
                            target: 'editing',
                            actions: assign({
                                texts: (_, {data}) => data.response
                            })
                        },
                        onError: {}
                    }
                },
                editing: {
                    invoke: {
                        id: 'dataOverviewForm',
                        src: 'dataOverviewForm',
                        data: {
                            user: ({user}) => user || {},
                            registrationData: ({registrationData}) => registrationData || {},
                            texts: ({texts}) => texts || [],
                            error: {
                                data: {}
                            },
                            userName: ({user}) => getName(user)
                        },
                        onDone: {
                            actions: [
                                assign({
                                    registrationData: (_, {data}) => data,
                                    user: ({user}, {data}) => {
                                        user.schoolApplication = data;

                                        return user;
                                    }
                                }),
                                send('DONE')
                            ]
                        },
                        onError: {}
                    }
                }
            },
            on: {
                BACK: {
                    target: '#documentsUpload'
                },
                CHANGEPASSWORD: {
                    target: 'changePassword'
                },
                DONE: [
                    {
                        target: '#registrationComplete',
                        cond: ({user}) => {
                            return user.schoolApplication.complete === 1
                        }
                    },
                    {
                        target: '#dataOverview.editing'
                    }
                ]
            }
        },
        changePassword: {
            initial: 'edit',
            states: {
                edit: {
                    on: {
                        SUBMIT: {
                            target: 'changePassword'
                        },
                        BACK: {
                            target: '#resumeForm'
                        }
                    }
                },
                changePassword: {
                    invoke: {
                        src: 'apiCall',
                        data: {
                            ...context,
                            url: api.changePasswordByUserApiUrlTemplate,
                            method: 'POST',
                            data: (_, {oldPassword, password, passwordRepeat}) => ({
                                password,
                                passwordRepeat,
                                oldPassword
                            })
                        },
                        onDone: {
                            target: 'changePasswordSuccess'
                        },
                        onError: {
                            actions: assign({
                                error: (_, {data}) => ({data})
                            }),
                            target: 'edit'
                        }
                    }
                },
                changePasswordSuccess: {
                    on: {
                        BACK: {
                            target: '#resumeForm'
                        }
                    }
                },
                changePasswordError: {
                    on: {
                        BACK: {
                            target: '#resumeForm'
                        }
                    }
                }
            }
        },
        registrationComplete: {
            id: 'registrationComplete',
            type: 'final',
            data: ({user, registrationData}) => {
                user.schoolApplication = registrationData;

                return user;
            }
        }
    }
});

export default applicationFormMachine;
