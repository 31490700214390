'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import {useSelector} from '@xstate/react';

import Logo         from '../../assets/images/logo.jpg'
import {Navigation} from '../navigation';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = ({authService}) => {

    const isAuthorized = useSelector(authService, state => state.matches('authorized'));

    return (
        <>
            <div className="header">
                <div>
                    <div className={'full-height vertical-align pr-8'}>
                        <div className={'header-container'}>
                            <div className={'logo'}>
                                <img src={Logo}></img>
                            </div>

                            <Navigation authService={authService} showAccountInfo={isAuthorized}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Header.propTypes = {
    authService: PropTypes.any
};
