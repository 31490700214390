'use strict';

import React               from 'react';
import PropTypes           from 'prop-types'
import {MdArrowBackIosNew} from 'react-icons/md'

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ProgressBar = ({valueNow, valueMin, valueMax, hasBackLink, onBackClick}) => {

    const calcProgress = (valueNow / valueMax) * 100;

    return (
        <div className="grid-container grid-y progress-container">

            <div className={'progressInfo'}>
                {hasBackLink() &&
                    <button
                        className={'backLink'}
                        type={'button'}
                        onClick={onBackClick}
                    >
                        <MdArrowBackIosNew style={{marginRight: '5px'}}/>
                        ZURÜCK
                    </button>
                }

                <span className={'step'}>Schritt {valueNow} von {valueMax}</span>
            </div>

            <div
                className="progress"
                role="progressbar"
                aria-valuenow={valueNow}
                aria-valuemin={valueMin}
                aria-valuemax={valueMax}
            >
                <div className="progress-meter" style={{width: calcProgress.toString().concat('', '%')}}></div>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    valueNow: PropTypes.number,
    valueMin: PropTypes.number,
    valueMax: PropTypes.number,
    hasBackLink: PropTypes.func,
    onBackClick: PropTypes.func
};