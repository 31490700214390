'use strict';

import React                          from 'react';
import PropTypes                      from 'prop-types';
import {useActor}                     from '@xstate/react';
import {GoTriangleUp, GoTriangleDown} from 'react-icons/go';
import {BiLogOut}                     from 'react-icons/bi';
import {FaUserCircle}                 from 'react-icons/fa';
import {InfoGuide}                    from '../info-guide';

import {ChangePasswordButtonWrapper} from '../changePasswordButtonWrapper';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Navigation = ({authService, ...props}) => {

    const [, send] = useActor(authService);
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <nav className="menu">
                <InfoGuide/>
                {props.showAccountInfo &&
                    <div className={'account-info'}>
                        <button
                            type={'button'}
                            className={'settings-btn'}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <FaUserCircle className={'icon-account'}/>
                            Konto
                        </button>

                        {open
                            ? <GoTriangleUp className="triangle"/>
                            : <GoTriangleDown className="triangle"/>
                        }

                        {open &&
                        <>
                            <div className="nav-content">
                                <ul className="main-nav-list">
                                    <ChangePasswordButtonWrapper
                                        service={authService}
                                        changeOpenState={setOpen}
                                    />
                                    <li>
                                        <div className="logout">
                                            <button
                                                className={'clear button'}
                                                type={'button'}
                                                onClick={
                                                    () => {
                                                        setOpen(false);
                                                        send('LOGOUT');
                                                    }
                                                }
                                            >
                                                <BiLogOut className="nav-icon"/>
                                                <span>Abmelden</span>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </>
                        }
                    </div>
                }
            </nav>
        </>
    );
};

Navigation.propTypes = {
    authService: PropTypes.any,
    showAccountInfo: PropTypes.bool
};
